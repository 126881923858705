import constantTestBuilder from "../../../mixins/Support/constantTestBuilder";

export default {
  mixins: [
    constantTestBuilder,
  ],
  computed: {
    element_id: {
      get() {
        return this.$store.state.elementStore.element_id;
      },
      set(value) {
        this.$store.commit("elementStore/setElementId", value);
      },
    },
    type_element: {
      get() {
        return this.$store.state.elementStore.type_element;
      },
      set(value) {
        this.$store.commit("elementStore/setTypeElement", value);
      },
    },
    element_flashcards: {
      get() {
        return this.$store.state.elementStore.element_flashcards;
      },
      set(value) {
        this.$store.commit("elementStore/setElementFlashcards", value);
      },
    },
  },
  methods: {
    getDataDefaultElementFlashcard() {
      return [
        {
          id: null,
          element_id: this.element_id,
          offset: 1,
          word: null,
          phonetic: null,
          word_type: null,
          meaning: null,
          example: "",
          audio_data: null,
          audio_file: null,
          image_data: null,
          image_file: null,
        }
      ];
    },
    getValueElement(type) {
      let valueElement = null;
      if (type === "Audio") valueElement = this.element_type_constant.AUDIO;
      else if (type === "Image") valueElement = this.element_type_constant.IMAGE;
      else if (type === "Video") valueElement = this.element_type_constant.VIDEO;
      // else if( type === 'Embed_yt') valueElement = 4;
      else if (type === "Post") valueElement = this.element_type_constant.POST
      else if (type === "Pdf") valueElement = this.element_type_constant.PDF;
      else if (type === "Album") valueElement = this.element_type_constant.ALBUM;
      else if (type === "Embed_yt") valueElement = this.element_type_constant.YOUTUBE;
      else if (type === "Flashcard") valueElement = this.element_type_constant.FLASHCARD;
      return valueElement;
    },
  }
}