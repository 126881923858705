<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="dialogSubElement"
        persistent
        max-width="1400px"
        :retain-focus="false"
        scrollable
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Sub Element</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogSubElement = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <ElementChoose
                    :hasAccessConfigFlashcard="model_sub_element === 'section'"
                  ></ElementChoose>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darken-1" text @click="dialogSubElement = false"
              >Đóng</v-btn
            >
            <v-btn
              color="blue darken-1"
              class="font-weight-bold"
              text
              @click="btnAddSubElement"
              outlined
              >Lưu</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ApiService from "@/service/api.service";
import { mapState, mapGetters } from "vuex";
import constantTestBuilder from "../mixins/Support/constantTestBuilder";
import elementSupport from "./Support/Element/elementSupport";
export default {
  name: "SubElementModal",
  components: {
    ElementChoose: () => import("./ElementChoose"),
  },
  data() {
    return {};
  },
  mixins: [
      constantTestBuilder,
      elementSupport,
  ],
  computed: {
    ...mapState({
      type_form_element: (state) => state.elementStore.type_form_element,
      model_sub_element: (state) => state.elementStore.model_sub_element,
      section_id: (state) => state.contentTestBuilderStore.section_id,
      exercise_id: (state) => state.contentTestBuilderStore.exercise_id,
    }),
    ...mapGetters({
      skills: "contentTestBuilderStore/skills",
    }),
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
    indexSkill: {
      get() {
        return this.$store.state.contentTestBuilderStore.indexSkill;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setIndexSkill", value);
      },
    },
    indexSection: {
      get() {
        return this.$store.state.contentTestBuilderStore.indexSection;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setIndexSection", value);
      },
    },
    dialogSubElement: {
      get() {
        return this.$store.state.elementStore.dialogSubElement;
      },
      set(value) {
        this.$store.commit(
          "elementStore/setDialogSubElement",
          value
        );
      },
    },
    element_id: {
      get() {
        return this.$store.state.elementStore.element_id;
      },
      set(value) {
        this.$store.commit("elementStore/setElementId", value);
      },
    },
    type_element: {
      get() {
        return this.$store.state.elementStore.type_element;
      },
      set(value) {
        this.$store.commit("elementStore/setTypeElement", value);
      },
    },
    element_post_data: {
      get() {
        return this.$store.state.elementStore.element_post_data;
      },
      set(value) {
        this.$store.commit("elementStore/setElementPostData", value);
      },
    },
    element_audio_data: {
      get() {
        return this.$store.state.elementStore.element_audio_data;
      },
      set(value) {
        this.$store.commit(
          "elementStore/setElementAudioData",
          value
        );
      },
    },
    element_image_data: {
      get() {
        return this.$store.state.elementStore.element_image_data;
      },
      set(value) {
        this.$store.commit(
          "elementStore/setElementImageData",
          value
        );
      },
    },
    element_audio_file: {
      get() {
        return this.$store.state.elementStore.element_audio_file;
      },
      set(value) {
        this.$store.commit(
          "elementStore/setElementAudioFile",
          value
        );
      },
    },
    element_image_file: {
      get() {
        return this.$store.state.elementStore.element_image_file;
      },
      set(value) {
        this.$store.commit(
          "elementStore/setElementImageFile",
          value
        );
      },
    },
    element_form_display: {
      get() {
        return this.$store.state.elementStore.element_form_display;
      },
      set(value) {
        this.$store.commit(
          "elementStore/setElementFormDisplay",
          value
        );
      },
    },
    video_element_id: {
      get() {
        return this.$store.state.elementStore.video_element_id;
      },
      set(value) {
        this.$store.commit("elementStore/setVideoElementId", value);
      },
    },
    video_element_data: {
      get() {
        return this.$store.state.elementStore.video_element_data;
      },
      set(value) {
        this.$store.commit(
          "elementStore/setVideoElementData",
          value
        );
      },
    },
    indexExercise: {
      get() {
        return this.$store.state.contentTestBuilderStore.indexExercise;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setIndexExercise", value);
      },
    },
    model_sub_element: {
      get() {
        return this.$store.state.elementStore.model_sub_element;
      },
      set(value) {
        this.$store.commit("elementStore/setModelSubElement", value);
      },
    },
    element_pdf_file: {
      get() {
        return this.$store.state.elementStore.element_pdf_file;
      },
      set(value) {
        this.$store.commit("elementStore/setElementPdfFile", value);
      },
    },
    element_pdf_data: {
      get() {
        return this.$store.state.elementStore.element_pdf_data;
      },
      set(value) {
        this.$store.commit("elementStore/setElementPdfData", value);
      },
    },
    element_flashcards: {
      get() {
        return this.$store.state.elementStore.element_flashcards;
      },
      set(value) {
        this.$store.commit("elementStore/setElementFlashcards", value);
      },
    },
  },
  watch: {
    dialogSubElement: function (val) {
      if (val == true) {
        this.setDataInput();
      }
    },
  },
  created() {},
  methods: {
    errorMess(mess) {
      this.$toasted.error(mess, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },
    setDataInput() {
      if (this.type_form_element === "add") {
        this.type_element = null;
        this.element_post_data = null;
        this.element_image_data = null;
        this.element_audio_data = null;
        this.element_audio_file = null;
        this.element_image_file = null;
        this.video_element_data = null;
        this.video_element_id = null;
        this.element_id = null;
        this.element_pdf_file = null;
        this.element_pdf_data = null;
        this.element_flashcards = this.getDataDefaultElementFlashcard();
      } else {
        let data = [];
        if (this.model_sub_element === "section") {
          data = this.skills[this.indexSkill].sections[this.indexSection];
        } else if (this.model_sub_element === "exercise") {
          data = this.skills[this.indexSkill].sections[this.indexSection].exercises[this.indexExercise];
        }
        this.type_element = data.sub_element_type;
        this.element_post_data = data.sub_element_type === "Post" ? data.sub_element_data : null;
        this.element_image_data = data.sub_element_type === "Image" ? data.sub_element_data : null;
        this.element_audio_data = data.sub_element_type === "Audio" ? data.sub_element_data : null;
        this.video_element_data = data.sub_element_type === "Video" ? data.sub_element_data : null;
        this.element_audio_file = null;
        this.element_image_file = null;
        this.video_element_id = data.sub_element_type === "Video" ? data.sub_element_id : null;
        this.element_id = this.checkElementId(data.sub_element_type, data.sub_element_id);
        console.log(this.element_id);
        this.element_pdf_file = null;
        this.element_pdf_data = data.sub_element_type === "Pdf" ? data.sub_element_data : null;
        if (data.element_type === "Flashcard") {
          this.element_flashcards = data.sub_element_data.map((item) => {
            item.example = item.example ? item.example : '';
            item.audio_data = item.audio_path;
            item.audio_file = null;
            item.image_data = item.image_path;
            item.image_file = null;
            return item;
          })
        } else {
          this.element_flashcards = this.getDataDefaultElementFlashcard();
        }
      }
    },
    checkElementId(type, element_id) {
      let check_element_id = element_id;
      if (type === "Video" || type === "Album") {
        check_element_id = null;
      }
      return check_element_id;
    },
    validateFormElement() {
      let flat = true;
      let vm = this;
      if (this.type_element == null) {
        vm.errorMess("Hãy chọn loại element !!");
        flat = false;
      }
      if (this.type_element === "Video") {
        if (this.video_element_id == null) {
          vm.errorMess("Hãy chọn video !!");
          flat = false;
        }
      }
      if (this.type_element === "Image") {
        if (this.element_image_data == null) {
          vm.errorMess("Hãy chọn ảnh !!");
          flat = false;
        }
      }
      if (this.type_element === "Audio") {
        if (this.element_audio_data == null) {
          vm.errorMess("Hãy chọn audio !!");
          flat = false;
        }
      }
      if (this.type_element === "Pdf") {
        if (this.element_pdf_data == null) {
          vm.errorMess("Hãy chọn file pdf !!");
          flat = false;
        }
      }
      if (this.type_element === "Post") {
        if (this.element_post_data == null) {
          vm.errorMess("Hãy nhập dữ liệu !!");
          flat = false;
        }
      }
      if (this.type_element === "Flashcard") {
        if (this.element_flashcards.length <= 0) {
          this.messError("Bạn chưa nhập dữ liệu !!");
          flat = false;
        } else {
          for (let i = 0; i < this.element_flashcards.length; i++) {
            let flashcard = this.element_flashcards[i];
            if (this.$utils.isValueInvalid(flashcard.word)) {
              this.messError("Bạn chưa nhập từ ở card " + (i + 1));
              flat = false;
            }
            if (this.$utils.isValueInvalid(flashcard.meaning)) {
              this.messError("Bạn chưa nhập giải nghĩa ở card " + (i + 1));
              flat = false;
            }
          }
        }
      }
      return flat;
    },
    async uploadSubElement(url, valueElement, subElementId, subElementData) {
      let vm = this;
      let formData = new FormData();
      formData.append("type", valueElement);
      formData.append("section_id", this.section_id);
      formData.append("exercise_id", this.exercise_id);
      formData.append("sub_element_id", subElementId);
      if (!this.checkIsElementTypeForUploadFileByTypeTxt(this.type_element)) {
        formData.append("editor", this.checkElementData(this.type_element));
      }
      formData.append("file", this.checkElementFile(this.type_element));
      if (this.type_element === "Flashcard") {
        subElementData = subElementData.map((item) => {
          item.element_id = subElementId;
          item.example = !vm.$utils.isValueInvalid(item.example) ? item.example : null;
          return item;
        });
        formData.append("data_flashcard", JSON.stringify(subElementData));
        // formData.append("flashcards", JSON.stringify(subElementData));
      }
      this.is_call_api = true;
      await ApiService.post(url, formData)
          .then((response) => {
            if (response.status === 200) {
              if (vm.type_element === "Flashcard") {
                subElementData = response.data.flashcards;
                subElementData = subElementData.map((item) => {
                  item.example = item.example ? item.example : '';
                  return item;
                });
              }
              if (vm.type_element !== "Flashcard") {
                subElementId = response.data.element_code;
              }
              if (vm.model_sub_element === "section") {
                vm.skills[vm.indexSkill].sections[vm.indexSection].sub_element_type = vm.type_element;
                vm.skills[vm.indexSkill].sections[vm.indexSection].sub_element_data = subElementData;
                vm.skills[vm.indexSkill].sections[vm.indexSection].sub_element_id = subElementId;
              } else {
                vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].sub_element_type = vm.type_element;
                vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].sub_element_data = subElementData;
                vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].sub_element_id = subElementId;
              }
              vm.$set(vm.skills);
              vm.type_element = null;
              vm.element_data = null;
              vm.dialogSubElement = false;
              vm.is_call_api = false;
            }
          })
          .catch((error) => {
            if (error) {
              vm.is_call_api = false;
              vm.errorMess("Upload not successfully. Please try again or contact admin !!");
              console.log(error);
            }
          });
    },
    async btnAddSubElement() {
      let validate = this.validateFormElement();
      if (!validate) {
        return;
      }
      this.is_call_api = true;
      let valueElement = this.getValueElement(this.type_element);
      let url = this.model_sub_element === "section" ? "prep-app/test/draft/section/upload-sub-element" : "prep-app/test/draft/exercise/upload-sub-element";
      if (this.type_element === "Video") {
        await this.uploadSubElement(url, valueElement, this.video_element_id, this.video_element_data);
      } else if (this.type_element === "Flashcard") {
        await this.uploadSubElement(url, valueElement, this.element_id, this.element_flashcards);
      } else {
        await this.uploadSubElement(url, valueElement, this.element_id, this.checkElementData(this.type_element));
      }
    },
    checkElementData(type) {
      if (type === "Audio") {
        return this.element_audio_data;
      }
      if (type === "Image") {
        return this.element_image_data;
      }
      if (type === "Post") {
        return this.element_post_data;
      }
      if (type === "Pdf") {
        return this.element_pdf_data;
      }
      return null;
    },
    checkElementFile(type) {
      if (type === "Image") {
        return this.element_image_file;
      }
      if (type === "Audio") {
        return this.element_audio_file;
      }
      if (type === "Pdf") {
        return this.element_pdf_file;
      }
      return null;
    },
  },
};
</script>

<style></style>
