export default {
  computed: {

  },
  methods: {
    convertInputDataMultipleChoice(data) {
      let dataConvertOption = [];
      let dataConvertCorrect = [];
      for (let i = 0; i < data.length; i++) {
        dataConvertOption.push(data[i].value);
        if (data[i].checked) {
          dataConvertCorrect.push({
            value: data[i].value,
            index: i,
          });
        }
      }
      return {
        dataConvertOption,
        dataConvertCorrect,
      };
    },
    convertDisplayMultipleChoice(option, correct) {
      let data = [];
      for (let iO = 0; iO < option.length; iO++) {
        data[iO] = {
          value: option[iO],
          checked: false,
        };
        for (let iC = 0; iC < correct.length; iC++) {
          if (correct[iC].index === iO) {
            data[iO].checked = true;
          }
        }
      }
      return data;
    },

  },
  filters: {}
}