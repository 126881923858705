<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="dialogChooseVideo"
        persistent
        max-width="1000px"
        scrollable
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Choose Video</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogChooseVideo = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" style="display: inline">
                  <template v-for="(video, i) in videos">
                    <v-col cols="3" :key="i" style="display: inline-block">
                      <!--                     ! vimeo-->
                      <b-embed
                        type="iframe"
                        aspect="16by9"
                        :src="video.media_path"
                      ></b-embed>

                      <!--                      !muse-->
                      <!--                      <b-embed-->
                      <!--                        type="iframe"-->
                      <!--                        aspect="16by9"-->
                      <!--                        :src="'https://muse.ai/embed/'+ video.muse_video_id+'?'"-->
                      <!--                      ></b-embed>-->

                      <v-radio-group
                        v-model="video_element_data"
                        class="mt-0 mb-0 pt-0"
                        style="max-height: 25px"
                      >
                        <!--                        vimeo-->
                        <v-radio
                          :label="video.name"
                          :value="video.media_path"
                          @click="addVideoElement(video)"
                        ></v-radio>

                        <!--                        muse-->
                        <!--                        <v-radio :label="video.name" :value="video.muse_video_id" @click="addVideoElement(video)"></v-radio>-->
                      </v-radio-group>
                    </v-col>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogChooseVideo = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "VideoModal",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      videos: (state) => state.elementStore.videos,
    }),
    ...mapGetters({}),
    dialogChooseVideo: {
      get() {
        return this.$store.state.elementStore.dialogChooseVideo;
      },
      set(value) {
        this.$store.commit(
          "elementStore/setDialogChooseVideo",
          value
        );
      },
    },
    element_data: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_data;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setElementData", value);
      },
    },
    video_element_data: {
      get() {
        return this.$store.state.elementStore.video_element_data;
      },
      set(value) {
        this.$store.commit(
          "elementStore/setVideoElementData",
          value
        );
      },
    },
    video_element_id: {
      get() {
        return this.$store.state.elementStore.video_element_id;
      },
      set(value) {
        this.$store.commit("elementStore/setVideoElementId", value);
      },
    },
  },
  methods: {
    addVideoElement(video) {
      // this.video_element_data;
      this.video_element_id = video.id;
      this.dialogChooseVideo = false;
    },
  },
};
</script>

<style></style>
