<template>
  <div>
    <v-row justify="center">
      <v-dialog
          v-model="dialogQuestionIeltsVWRPart1"
          persistent
          max-width="1400px"
          scrollable
          :retain-focus="false"
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span v-if="type_form_question_ielts_vwr_part1 === 'add'" class="headline"
              >Thêm câu hỏi (VWR)</span
              >
              <span v-if="type_form_question_ielts_vwr_part1 === 'update'" class="headline"
              >Cập nhật câu hỏi (VWR)</span
              >
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogQuestionIeltsVWRPart1 = false" right
              >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="2">
                  <p class="text-h6 black--text">Title:</p>
                </v-col>
                <v-col cols="6">
                  <EditorBalloon v-model="title_input" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <p class="text-h6 black--text">Description:</p></v-col
                >
                <v-col cols="6">
                  <EditorBasic v-model="description_input" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <p class="text-h6 black--text">Element:</p>
                </v-col>
                <v-col cols="8" class="m-0 p-0 pl-2">
                  <ElementChoose :hasInputDescriptionImage="true" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <p class="text-h6 black--text">Chọn loại câu hỏi:</p>
                </v-col>
                <v-col cols="6">
                  <v-select
                      :items="writingPartOneTypeList"
                      label="Bấm để chọn"
                      item-text="title"
                      item-value="code"
                      required
                      outlined
                      dense
                      v-model="writing_part1_type_input"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="2">
                  <p class="text-h6 black--text">Thời gian làm bài (phút:giây):</p>
                </v-col>
                <v-col cols="5">
                  <date-picker
                      v-model="limit_time"
                      type="time"
                      format="mm:ss"
                      value-type="format"
                      placeholder="mm:ss"
                  ></date-picker>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="2">
                  <p class="text-h6 black--text">Outline</p>
                </v-col>
                <v-col cols="10">
                  <template v-for="(structure_question, iStructure) in structure_questions">
                    <div :key="iStructure" class="mb-2" style="width: 100%">
                      <p class="text-h6 black--text mb-0">
                        {{ structure_question.name }}
                      </p>
                      <template v-if="structure_question.code === 'introduction_and_overview'">
                        <EditorBalloon class="mb-5" placeholder="Nhập outline cho đoạn Introduction - Overview" v-model="structure_question.outline" />
                      </template>
                      <template v-else>
<!--                        <v-text-field-->
<!--                            :placeholder="'Nhập outline cho đoạn Body ' + iStructure"-->
<!--                            outlined dense-->
<!--                            v-model="structure_question.outline"-->
<!--                        ></v-text-field>-->
                        <v-textarea
                            :placeholder="'Nhập outline cho đoạn Body ' + iStructure"
                            outlined dense
                            v-model="structure_question.outline"
                        ></v-textarea>
                      </template>
                    </div>

                  </template>
                </v-col>
              </v-row>

              <v-row class="mt-0">
                <v-col cols="2">
                  <p class="text-h6 black--text">Sample:</p>
                </v-col>
                <v-col cols="10">
                  <Editor v-model="sample_input"></Editor>
                </v-col>
              </v-row>

            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darken-1" text @click="dialogQuestionIeltsVWRPart1 = false"
            >Đóng</v-btn
            >
            <v-btn
                outlined
                class="font-weight-bold"
                color="blue darken-1"
                text
                v-if="type_form_question_ielts_vwr_part1 === 'add'"
                @click="btnAddQuestionVwrIeltsTask1()"
            >Thêm</v-btn
            >
            <v-btn
                outlined
                class="font-weight-bold"
                color="blue darken-1"
                text
                v-if="type_form_question_ielts_vwr_part1 === 'update'"
                @click="btnEditQuestionVwrIeltsTask1()"
            >Cập nhật</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ApiService from "@/service/api.service";
import weightSupport from "./Support/Question/weightSupport";
import Editor from "../../../../components/ckeditor5/Editor";

export default {
  name: "QuestionVWRIeltsTask1Modal",
  components: {
    Editor,
    EditorBalloon: () => import("../../../../components/ckeditor5/EditorBalloon"),
    // Editor: () => import('../../../../components/ckeditor5/Editor'),
    EditorBasic: () => import("../../../../components/ckeditor5/EditorBasic"),
    ElementChoose: () => import("./ElementChoose"),
  },
  mixins: [
    weightSupport
  ],
  data() {
    return {
      toggle_element: true,

      structure_questions: [],
      title_input: "",
      description_input: "",
      writingPartOneTypeList: [],

      data_type_question: null,
      writing_part1_id: null,
      writing_part1_type_input: null,
      limit_time: "",
      sample_input: null,
    };
  },
  computed: {
    ...mapState({
      type_form_question_ielts_vwr_part1: (state) => state.contentTestBuilderStore.type_form_question_ielts_vwr_part1,
      exercise_id: (state) => state.contentTestBuilderStore.exercise_id,
      question_id: (state) => state.contentTestBuilderStore.question_id,
      indexSkill: (state) => state.contentTestBuilderStore.indexSkill,
      indexSection: (state) => state.contentTestBuilderStore.indexSection,
      indexExercise: (state) => state.contentTestBuilderStore.indexExercise,
      indexQuestion: (state) => state.contentTestBuilderStore.indexQuestion,
    }),
    ...mapGetters({
      skills: "contentTestBuilderStore/skills",
    }),
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
    dialogQuestionIeltsVWRPart1: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogQuestionIeltsVWRPart1;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setDialogQuestionIeltsVWRPart1", value);
      },
    },
    type_element: {
      get() {
        return this.$store.state.elementStore.type_element;
      },
      set(value) {
        this.$store.commit("elementStore/setTypeElement", value);
      },
    },
    element_post_data: {
      get() {
        return this.$store.state.elementStore.element_post_data;
      },
      set(value) {
        this.$store.commit("elementStore/setElementPostData", value);
      },
    },
    element_audio_data: {
      get() {
        return this.$store.state.elementStore.element_audio_data;
      },
      set(value) {
        this.$store.commit(
            "elementStore/setElementAudioData",
            value
        );
      },
    },
    element_image_data: {
      get() {
        return this.$store.state.elementStore.element_image_data;
      },
      set(value) {
        this.$store.commit(
            "elementStore/setElementImageData",
            value
        );
      },
    },
    element_audio_file: {
      get() {
        return this.$store.state.elementStore.element_audio_file;
      },
      set(value) {
        this.$store.commit(
            "elementStore/setElementAudioFile",
            value
        );
      },
    },
    element_image_file: {
      get() {
        return this.$store.state.elementStore.element_image_file;
      },
      set(value) {
        this.$store.commit(
            "elementStore/setElementImageFile",
            value
        );
      },
    },
    element_id: {
      get() {
        return this.$store.state.elementStore.element_id;
      },
      set(value) {
        this.$store.commit("elementStore/setElementId", value);
      },
    },
    element_form_display: {
      get() {
        return this.$store.state.elementStore.element_form_display;
      },
      set(value) {
        this.$store.commit(
            "elementStore/setElementFormDisplay",
            value
        );
      },
    },
    video_element_id: {
      get() {
        return this.$store.state.elementStore.video_element_id;
      },
      set(value) {
        this.$store.commit("elementStore/setVideoElementId", value);
      },
    },
    video_element_data: {
      get() {
        return this.$store.state.elementStore.video_element_data;
      },
      set(value) {
        this.$store.commit(
            "elementStore/setVideoElementData",
            value
        );
      },
    },
    element_description: {
      get() {
        return this.$store.state.elementStore.element_description;
      },
      set(value) {
        this.$store.commit("elementStore/setElementDescription", value);
      },
    },
  },
  watch: {
    dialogQuestionIeltsVWRPart1(val) {
      if (val) {
        this.getWritingPartOneTypeList();
        this.setDataInput();

        if (this.type_form_question_ielts_vwr_part1 === 'add') {
          let validateQuestionLimit = this.validateQuestionLimit();
          if (!validateQuestionLimit) {
            this.$nextTick(() => {
              this.dialogQuestionIeltsVWRPart1 = false;
            })
          }
        }
      } else {
        this.writing_part1_id = null;
        this.structure_questions = [];
      }
    },
    type_element(val, oldVal) {
      if (this.dialogQuestionIeltsVWRPart1) {
        if (!(val === 'Image' || val == null)) {
          this.messError('Chỉ được chọn loại element là Image cho phần này');
          this.$nextTick(() => {
            this.type_element = oldVal;
          })
        }
      }
    },
  },
  methods: {
    setDataInput() {
      this.data_type_question = this.skills[this.indexSkill].sections[this.indexSection].exercises[this.indexExercise].exercise_type;
      if (this.type_form_question_ielts_vwr_part1 === "add") {
        this.writing_part1_type_input = null;
        this.limit_time = "20:00";
        this.sample_input = '';

        this.structure_questions = this.getStructureQuestionIeltsVWRPart1();
        this.title_input = "";
        this.description_input = "";
        this.type_element = 'Image';
        this.element_post_data = null;
        this.element_image_data = null;
        this.element_audio_data = null;
        this.element_audio_file = null;
        this.element_image_file = null;
        this.element_id = null;
        this.video_element_data = null;
        this.video_element_id = null;
        this.element_description = '';
      }
      if (this.type_form_question_ielts_vwr_part1 === "update") {
        let data = this.skills[this.indexSkill].sections[this.indexSection].exercises[this.indexExercise].questions[this.indexQuestion];
        this.title_input = data.title;
        this.description_input = data.description ? data.description : "";

        this.writing_part1_type_input = data.writing_part_type;

        this.structure_questions = data.writing_part;
        this.writing_part1_id = data.writing_part_id;

        this.element_id = data.element_id;
        this.element_image_data = data.element_data;
        this.element_image_file = null;

        this.limit_time = data.limit_time ? this.$options.filters.convertSecondToMS(data.limit_time) : null;
        this.type_element = 'Image';
        this.element_description = data.element_description ?? '';
        this.sample_input = !data.sample ? "" : data.sample;
        this.is_call_api = false;
      }
    },
    async getWritingPartOneTypeList() {
      let vm = this;
      try {
        let res = await ApiService.get("prep-app/test/draft/question/ielts-writing-part-one-type-list");
        if (res.status === 200) {
          vm.writingPartOneTypeList = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    getStructureQuestionIeltsVWRPart1() {
      return [
        {
          "code": "introduction",
          "name": "Introduction and Overview",
          "sample": "",
          "outline": ""
        },
        {
          "code": "body_1",
          "name": "Body 1",
          "sample": "",
          "outline": ""
        },
        {
          "code": "body_2",
          "name": "Body 2",
          "sample": "",
          "outline": ""
        }
      ];
    },
    validateFormQuestion() {
      let flat = true;
      // let skill = this.skills[this.indexSkill];
      if (this.title_input == null || this.title_input === "") {
        this.messError('Bạn chưa nhập tiêu đề question');
        flat = false;
      }
      if (this.writing_part1_type_input == null || this.writing_part1_type_input === "") {
        this.messError('Bạn chưa chọn loại câu hỏi');
        flat = false;
      }
      if (!this.element_image_data) {
        this.messError('Hãy nhập dữ liệu ảnh');
        flat = false;
      }
      if (!this.element_description) {
        this.messError('Bạn chưa nhập mô tả đề bài');
        flat = false;
      }

      return flat;
    },
    async btnAddQuestionVwrIeltsTask1() {
      let validateForm = this.validateFormQuestion();
      if (!validateForm) {
        return;
      }
      let vm = this;

      if (vm.toggle_element) {
        vm.is_call_api = true;
        let formData = new FormData();
        formData.append("element_id", vm.element_id);
        formData.append("type", 2); // Type 2: Image
        formData.append("file", vm.element_image_file);
        formData.append("description", vm.element_description);
        await ApiService.post("prep-app/test/draft/exercise/upload-element", formData)
        .then(function (response) {
          vm.element_id = response.data.element_code;
          vm.is_call_api = false;
        })
        .catch(function (error) {
          if (error) {
            vm.dialogQuestion = false;
            vm.is_call_api = false;
            vm.$toasted.error("Có lỗi xảy ra. Vui lòng liên hệ phòng kỹ thuật hỗ trợ", {theme: "toasted-primary", position: "top-right", duration: 4000,});
          }
        });

      }

      let data = {
        exercise_id: this.exercise_id,
        exercise_type: this.data_type_question,
        type: this.data_type_question,
        writing_part: this.structure_questions,
        title: this.title_input,
        writing_part_type: this.writing_part1_type_input,
        limit_time: this.limit_time ? this.$options.filters.convertMSToSecond(this.limit_time) : null,
        element_type: this.type_element,
        element_data: this.element_image_data,
        element_id: this.toggle_element === false ? null : this.element_id,
        element_description: this.element_description  ?? null,
        weight: 1,
      };
      if (this.description_input) data.description = this.description_input;
      if (this.sample_input) data.sample = this.sample_input;
      this.is_call_api = true;
      await ApiService.post("prep-app/test/draft/question", data)
          .then(function (res) {
            if (res.status === 200) {
              vm.handleAfterCreate(data, res);
              vm.dialogQuestionIeltsVWRPart1 = false;
              vm.$toasted.success("Them thanh cong", {theme: "toasted-primary", position: "top-right", duration: 4000,});
              vm.is_call_api = false;
            }
          })
          .catch(function (error) {
            if (error) {
              vm.dialogQuestionIeltsVWRPart1 = false;
              vm.is_call_api = false;
              vm.messError('Có lỗi xảy ra. Vui lòng liên hệ phòng kỹ thuật hỗ trợ')
            }
          });
    },
    handleAfterCreate(data, res) {
      let vm = this;
      let dataCheck = vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].questions;
      if (dataCheck === undefined || dataCheck === null) {
        vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].questions = [];
      }
      data.id = res.data.question_id;
      data.writing_part_id = res.data.writing_part_id;
      let indexRowQuestion = vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].questions.push(data) - 1;
      for (
          let indexExer = vm.skills[vm.indexSkill].sections[vm.indexSection].exercises.length - 1;
          indexExer >= vm.indexExercise + 1;
          indexExer--
      ) {
        vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[indexExer].offset_question++;
      }
      for (
          let indexSec = vm.skills[vm.indexSkill].sections.length - 1;
          indexSec >= vm.indexSection + 1;
          indexSec--
      ) {
        vm.skills[vm.indexSkill].sections[indexSec].offset_question++;
      }
      vm.skills[vm.indexSkill].sections[vm.indexSection].total_question++;
      vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].total_question++;
      vm.$set(vm.skills);
      vm.is_call_api = false;
      vm.dialogQuestion = false;
      setTimeout(() => {
        vm.$scrollTo("#question" + vm.indexSkill + vm.indexSection + vm.indexExercise + indexRowQuestion, 1000);
      }, 100);
    },
    async btnEditQuestionVwrIeltsTask1() {
      let validateForm = this.validateFormQuestion();
      if (!validateForm) {
        return;
      }
      let vm = this;

      if (vm.toggle_element) {
        vm.is_call_api = true;
        let formData = new FormData();
        formData.append("element_id", vm.element_id);
        formData.append("type", 2); // Type 2: Image
        formData.append("file", vm.element_image_file);
        formData.append("description", vm.element_description);
        await ApiService.post("prep-app/test/draft/exercise/upload-element", formData)
            .then(function (response) {
              vm.element_id = response.data.element_code;
              vm.is_call_api = false;
            })
            .catch(function (error) {
              if (error) {
                vm.dialogQuestion = false;
                vm.is_call_api = false;
                vm.$toasted.error("Có lỗi xảy ra. Vui lòng liên hệ phòng kỹ thuật hỗ trợ", {theme: "toasted-primary", position: "top-right", duration: 4000,});
              }
            });

      }

      let data = {
        id: this.question_id,
        exercise_id: this.exercise_id,
        exercise_type: this.data_type_question,
        type: this.data_type_question,
        writing_part: this.structure_questions,
        title: this.title_input,
        limit_time: this.limit_time ? this.$options.filters.convertMSToSecond(this.limit_time) : null,
        element_type: this.type_element,
        element_data: this.element_image_data,
        element_id: this.toggle_element === false ? null : this.element_id,
        element_description: this.element_description  ?? null,
        writing_part_id: this.writing_part1_id,
        writing_part_type: this.writing_part1_type_input,
        weight: 1,
      };
      if (this.description_input) data.description = this.description_input;
      if (this.sample_input) data.sample = this.sample_input;
      this.is_call_api = true;
      await ApiService.put("prep-app/test/draft/question/" + this.question_id, data)
          .then(function (res) {
            if (res.status === 200) {
              vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].questions[vm.indexQuestion] = data;
              vm.$set(vm.skills);
              vm.is_call_api = false;
              vm.$toasted.success("Cap nhap thanh cong", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.dialogQuestionIeltsVWRPart1 = false;
            }
          })
          .catch(function (error) {
            if (error) {
              vm.dialogQuestionIeltsVWRPart1 = false;
              vm.is_call_api = false;
              vm.messError('Có lỗi xảy ra. Vui lòng liên hệ phòng kỹ thuật hỗ trợ');

            }
          });
    },
    validateQuestionLimit() {
      let flat = true;
      let currentSection = this.skills[this.indexSkill].sections[this.indexSection];
      if (currentSection.question_limit != null) {
        let countQuestionBySection = currentSection.exercises.reduce((totalQuestion, exercise) => {
          let questionLength = exercise.questions !== undefined ? exercise.questions.length : 0;
          return totalQuestion + questionLength;
        },0);
        if (countQuestionBySection >= currentSection.question_limit) {
          this.messError("Số câu hỏi tối đa của phần này là "+currentSection.question_limit+" câu");
          flat = false;
        }
      }
      return flat;
    },
    messError(mess = '') {
      this.$toasted.error(mess, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },
  },
};
</script>

<style scoped></style>
