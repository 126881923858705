<template>
  <div style="width: 100%">
    <template v-for="(flashcard, cardIndex) in getSortedFlashcards()">
      <v-row :key="cardIndex" style="border: 1px darkgray solid" class="rounded mb-2">
        <v-col cols="12" class="d-flex">
          <p class="text-h6">Flashcard {{cardIndex+1}}</p>
          <v-btn outlined small color="red" class="ml-2" @click="deleteCardByIndex(cardIndex)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn outlined small color="blue" class="ml-2" @click="moveCardUp(cardIndex)">
            <v-icon>mdi-arrow-up</v-icon>
          </v-btn>
          <v-btn outlined small color="blue" class="ml-2" @click="moveCardDown(cardIndex)">
            <v-icon>mdi-arrow-down</v-icon>
          </v-btn>
        </v-col>

        <!--        côt 1-->
        <v-col cols="6">
          <v-text-field dense outlined v-model="flashcard.word">
            <template v-slot:label>
              Từ <span class="red--text">(*)</span>
            </template>
          </v-text-field>

          <v-text-field
              v-model="flashcard.phonetic"
              label="Phiên âm"
              dense outlined
          ></v-text-field>

          <v-text-field
              v-model="flashcard.word_type"
              label="Loại từ"
              dense outlined
          ></v-text-field>

          <div>
            <p class="mb-0 text-h6">Giải nghĩa <span class="red--text">(*)</span></p>
            <Editor v-model="flashcard.meaning" ></Editor>
          </div>
<!--          <v-text-field dense outlined v-model="flashcard.meaning">-->
<!--            <template v-slot:label>-->
<!--              Giải nghĩa <span class="red&#45;&#45;text">(*)</span>-->
<!--            </template>-->
<!--          </v-text-field>-->

          <div class="mt-6">
            <p class="mb-0 text-h6">Ví dụ</p>
            <Editor v-model="flashcard.example" ></Editor>
          </div>
<!--          <v-text-field-->
<!--              v-model="flashcard.example"-->
<!--              label="Ví dụ"-->
<!--              dense outlined-->
<!--          ></v-text-field>-->
        </v-col>
        <!--      hết cột 1-->

        <!--      cột 2-->
        <v-col cols="6">
          <div style="width: 100%">
            <v-file-input
                outlined dense
                class="mt-0"
                prepend-icon="mdi-music-note"
                accept="audio/*"
                label="Chọn audio cho từ"
                @change="(e) => onAudioFileOptionChange(e, cardIndex)"
                :value="flashcard.audio_file"
            ></v-file-input>
            <vuetify-audio
                v-if="flashcard.audio_data"
                :file="flashcard.audio_data"
                color="primary" class="mb-6"
            />
          </div>

          <div style="width: 100%" class="">
            <v-file-input
                outlined dense
                class="mt-0"
                prepend-icon="mdi-image"
                accept="image/png, image/jpeg, image/jpg"
                label="Chọn ảnh cho từ"
                @change="(e) => onImageFileOptionChange(e, cardIndex)"
                :value="flashcard.image_file"
            ></v-file-input>
            <v-img
                v-if="flashcard.image_data"
                :src="flashcard.image_data"
                max-width="100%" max-height="250" class="mb-2"
                contain
            />
          </div>
        </v-col>
        <!--      hết cột 2-->

      </v-row>
    </template>

    <v-btn outlined color="blue" @click="addFlashcard()" class="mt-2">
      <v-icon>mdi-plus</v-icon>
      Thêm flashcard
    </v-btn>
  </div>
</template>

<script>
import VuetifyAudio from "../../../marking-management/Audio";
import Swal from "sweetalert2";
import ApiService from "@/service/api.service";
import Editor from "../../../../../components/ckeditor5/Editor";

export default {
  name: "Flashcard",
  components: {Editor, VuetifyAudio},
  data() {
    return {}
  },
  computed: {
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
    element_id: {
      get() {
        return this.$store.state.elementStore.element_id;
      },
      set(value) {
        this.$store.commit("elementStore/setElementId", value);
      },
    },
    element_flashcards: {
      get() {
        return this.$store.state.elementStore.element_flashcards;
      },
      set(value) {
        this.$store.commit("elementStore/setElementFlashcards", value);
      },
    },
  },
  methods: {
    errorMess(mess) {
      this.$toasted.error(mess, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },
    getSortedFlashcards() {
      return this.element_flashcards.slice().sort((a, b) => a.offset - b.offset);
    },
    addFlashcard() {
      let newOffset = this.element_flashcards.length + 1;
      this.element_flashcards.push({
        id: null,
        element_id: this.element_id ?? null,
        offset: newOffset,
        word: "",
        phonetic: "",
        word_type: "",
        meaning: "",
        example: "",
        audio_data: null,
        audio_file: null,
        image_data: null,
        image_file: null,
      });
    },
    deleteCardByIndex(index) {
      if (this.element_flashcards.length === 1) {
        this.errorMess("Không thể xóa flashcard cuối cùng");
        return;
      }

      let vm = this;
      Swal.fire({
        title: "Bạn có chắc chắc muốn xóa flashcard này?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Xác nhận",
      }).then((result) => {
        if (result.value) {
          vm.element_flashcards.splice(index, 1);
          vm.updateOffsets();
        }
      });
    },
    moveCardUp(index) {
      if (index === 0) return;
      const temp = this.element_flashcards[index];
      this.element_flashcards[index] = this.element_flashcards[index - 1];
      this.element_flashcards[index - 1] = temp;
      this.updateOffsets();
    },
    moveCardDown(index) {
      if (index === this.element_flashcards.length - 1) return;
      const temp = this.element_flashcards[index];
      this.element_flashcards[index] = this.element_flashcards[index + 1];
      this.element_flashcards[index + 1] = temp;
      this.updateOffsets();
    },
    updateOffsets() {
      this.element_flashcards.forEach((flashcard, index) => {
        flashcard.offset = index + 1;
      });
    },
    async uploadFileAction(file) {
      let vm = this;
      vm.is_call_api = true;
      let url = "prep-app/upload-file";
      let formData = new FormData();
      formData.append("file", file);

      try {
        let response = await ApiService.post(url, formData);
        vm.is_call_api = false;
        return response.data.url;
      } catch (error) {
        vm.is_call_api = false;
        // vm.errorMess("Có lỗi xảy ra khi upload file. Liên hệ team kỹ thuật để được hỗ trợ.");
        return null;
      }
    },
    async onAudioFileOptionChange(file, indexOption) {
      let vm = this;
      if (file === undefined || file == null) {
        vm.element_flashcards[indexOption].audio_data = null;
        vm.element_flashcards[indexOption].audio_file = null;
        return;
      }
      // validate file size and type
      if (!["audio/mpeg", "audio/wav", "audio/ogg", "audio/mp3"].includes(file.type)) {
        vm.errorMess("File audio không đúng định dạng");
        return;
      }
      if (file.size > 5242880) {
        vm.errorMess("File audio không được lớn hơn 5MB");
        return;
      }

      // let reader = new FileReader();
      // reader.onload = (e) => {
      //   vm.element_flashcards[indexOption].audio_data = e.target.result;
      //   vm.element_flashcards[indexOption].audio_file = file;
      // };
      // reader.readAsDataURL(file);
      let audio_url = await vm.uploadFileAction(file);
      if (!audio_url) {
        vm.errorMess("Có lỗi xảy ra khi upload file audio. Liên hệ team kỹ thuật để được hỗ trợ.");
        return;
      }
      vm.element_flashcards[indexOption].audio_file = file;
      vm.element_flashcards[indexOption].audio_data = audio_url;

    },
    async onImageFileOptionChange(file, indexOption) {
      let vm = this;
      if (file === undefined || file == null) {
        vm.element_flashcards[indexOption].image_data = null;
        vm.element_flashcards[indexOption].image_file = null;
        return;
      }
      // validate file size and type
      if (!["image/png", "image/jpeg", "image/jpg"].includes(file.type)) {
        vm.errorMess("File ảnh không đúng định dạng");
        return;
      }
      if (file.size > 5242880) {
        vm.errorMess("File ảnh không được lớn hơn 5MB");
        return;
      }
      // let reader = new FileReader();
      // reader.onload = (e) => {
      //   vm.element_flashcards[indexOption].image_data = e.target.result;
      //   vm.element_flashcards[indexOption].image_file = file;
      // };
      // reader.readAsDataURL(file);
      let image_url = await vm.uploadFileAction(file);
      if (!image_url) {
        vm.errorMess("Có lỗi xảy ra khi upload file ảnh. Liên hệ team kỹ thuật để được hỗ trợ.");
        return;
      }
      vm.element_flashcards[indexOption].image_file = file;
      vm.element_flashcards[indexOption].image_data = image_url;
    },

  }
}
</script>

<style scoped>

</style>