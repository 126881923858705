<template>
  <div>
    <div class="d-flex">
      <div
          :id="'question' + iSkill + iSection + iExercise + iQuestion"
          class="d-flex"
          style="max-width: 50%"
      >
        <h4>
          <template v-if="type_expand === 0">
            {{ section.offset_question + exercise.offset_question + iQuestion + 1 }}.
          </template>
          <template v-if="type_expand === 1"> {{ iQuestion + 1 }}. </template>
        </h4>
        <!--      <h4 v-if="exercise.exercise_type === 'Virtual Writing Room'" class="ml-2">Câu hỏi cho VWR:</h4>-->
        <div
            class="ml-2"
            v-if=" !( question.type === exercise_type_constant.SENTENCE_STRESSES || question.type === exercise_type_constant.INDIVIDUAL_SOUNDS_AND_SENTENCE_STRESSES)"
            v-html="question.title"
        ></div>
      </div>
      <div>
        <v-icon
            @click=" editQuestion( iSkill, iSection, iExercise, iQuestion, exercise.id, question )"
            right
        >mdi-file-document-edit-outline</v-icon
        >
        <v-icon
            @click=" deleteQuestion(iSkill, iSection, iExercise, iQuestion, question.id)"
            right
        >mdi-delete</v-icon
        >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                v-bind="attrs"
                v-on="on"
                v-if="question.type == exercise_type_constant.SINGLE_CHOICE"
                @click="duplicateQuestion( iSkill, iSection, iExercise, iQuestion, question.id )"
                right
            >mdi-content-copy</v-icon
            >
          </template>
          <span>Duplicate question</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                @click="openDialogSortQuestion(iSkill, iSection, iExercise, iQuestion)"
                v-bind="attrs" v-on="on"
                right
            >mdi-sort</v-icon
            >
          </template>
          <span>Sắp xếp câu hỏi</span>
        </v-tooltip>
      </div>
    </div>
    <p v-if="displayQuestionType()" class="text-sm-subtitle-1 font-italic" style="color: #6495ed">
      Loại câu hỏi: {{ displayQuestionType() }}
    </p>
  </div>
</template>

<script>
import ApiService from "@/service/api.service";
import Swal from "sweetalert2";
import { mapState, mapGetters } from "vuex";
import constantTestBuilder from "../../mixins/Support/constantTestBuilder";
export default {
  name: "QuestionAction",
  props: {
    skill: {
      type: Object,
      default() {
        return {};
      },
    },
    section: {
      type: Object,
      default() {
        return {};
      },
    },
    exercise: {
      type: Object,
      default() {
        return {};
      },
    },
    question: {
      type: Object,
      default() {
        return {};
      },
    },
    iSkill: {
      type: Number,
      default: 0,
    },
    iSection: {
      type: Number,
      default: 0,
    },
    iExercise: {
      type: Number,
      default: 0,
    },
    iQuestion: {
      type: Number,
      default: 0,
    },
  },
  mixins: [
      constantTestBuilder,
  ],
  data() {
    return {};
  },
  computed: {
    ...mapState({
      type_expand: (state) => state.informationTestBuilderStore.type_expand,
    }),
    ...mapGetters("contentTestBuilderStore", ["skills"]),
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
    skill_id: {
      get() {
        return this.$store.state.contentTestBuilderStore.skill_id;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setSkillId", value);
      },
    },
    section_id: {
      get() {
        return this.$store.state.contentTestBuilderStore.section_id;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setSectionId", value);
      },
    },
    exercise_id: {
      get() {
        return this.$store.state.contentTestBuilderStore.exercise_id;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setExerciseId", value);
      },
    },
    question_id: {
      get() {
        return this.$store.state.contentTestBuilderStore.question_id;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setQuestionId", value);
      },
    },
    answer_id: {
      get() {
        return this.$store.state.contentTestBuilderStore.answer_id;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setAnswerId", value);
      },
    },
    indexSkill: {
      get() {
        return this.$store.state.contentTestBuilderStore.indexSkill;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setIndexSkill", value);
      },
    },
    indexSection: {
      get() {
        return this.$store.state.contentTestBuilderStore.indexSection;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setIndexSection", value);
      },
    },
    indexExercise: {
      get() {
        return this.$store.state.contentTestBuilderStore.indexExercise;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setIndexExercise", value);
      },
    },
    indexQuestion: {
      get() {
        return this.$store.state.contentTestBuilderStore.indexQuestion;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setIndexQuestion", value);
      },
    },
    type_form_question: {
      get() {
        return this.$store.state.contentTestBuilderStore.type_form_question;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setTypeFormQuestion",
          value
        );
      },
    },
    dialogQuestion: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogQuestion;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setDialogQuestion", value);
      },
    },
    element_form_display: {
      get() {
        return this.$store.state.elementStore.element_form_display;
      },
      set(value) {
        this.$store.commit(
          "elementStore/setElementFormDisplay",
          value
        );
      },
    },
    dialogQuestionVWR: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogQuestionVWR;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setDialogQuestionVWR",
          value
        );
      },
    },
    type_form_question_vwr: {
      get() {
        return this.$store.state.contentTestBuilderStore.type_form_question_vwr;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setTypeFormQuestionVWR",
          value
        );
      },
    },
    dialogSortQuestion: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogSortQuestion;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setDialogSortQuestion", value);
      },
    },
    dialogQuestionIeltsVWRPart1: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogQuestionIeltsVWRPart1;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setDialogQuestionIeltsVWRPart1", value);
      },
    },
    type_form_question_ielts_vwr_part1: {
      get() {
        return this.$store.state.contentTestBuilderStore.type_form_question_ielts_vwr_part1;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setTypeFormQuestionIeltsVwrPart1", value);
      },
    },
    dialogQuestionUniversal: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogQuestionUniversal;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setDialogQuestionUniversal", value);
      },
    },
    type_form_question_universal: {
      get() {
        return this.$store.state.contentTestBuilderStore.type_form_question_universal;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setTypeFormQuestionUniversal", value);
      },
    },
  },
  methods: {
    editQuestion(
      indexSkill,
      indexSection,
      indexExercise,
      indexQuestion,
      exercise_id,
      question
    ) {
      this.indexSkill = indexSkill;
      this.indexSection = indexSection;
      this.indexExercise = indexExercise;
      this.indexQuestion = indexQuestion;
      this.exercise_id = exercise_id;
      this.question_id = question.id;
      this.answer_id = question.answer_id;
      this.element_form_display = null;
      let exercise = this.skills[indexSkill].sections[indexSection].exercises[indexExercise];
      if (question.type === this.exercise_type_constant.VIRTUAL_WRITING_ROOM) {
        this.is_call_api = true;
        this.type_form_question_vwr = "update";
        this.dialogQuestionVWR = true;
      } else if (question.type === this.exercise_type_constant.VIRTUAL_WRITING_ROOM_WT1_IELTS) {
        this.type_form_question_ielts_vwr_part1 = 'update';
        this.dialogQuestionIeltsVWRPart1 = true;
      } else if (
          this.listExerciseTypeInUniversalType().includes(question.type) &&
          exercise.exercise_type === this.exercise_type_constant.UNIVERSAL
      ) {
        this.dialogQuestionUniversal = true;
        this.type_form_question_universal = 'update';
      } else {
        this.type_form_question = "update";
        this.dialogQuestion = true;
      }
    },
    deleteQuestion(
      indexSkill,
      indexSection,
      indexExercise,
      indexQuestion,
      question_id
    ) {
      let self = this;
      Swal.fire({
        title: "Bạn có chắc chắc muốn xóa thông tin này?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Xác nhận",
      }).then((result) => {
        if (result.value) {
          self.is_call_api = true;
          ApiService.delete("prep-app/test/draft/question/" + question_id)
            .then(function (res) {
              if (res.status == 200) {
                for (
                  let indexExer =
                    self.skills[indexSkill].sections[indexSection].exercises
                      .length - 1;
                  indexExer >= indexExercise + 1;
                  indexExer--
                ) {
                  self.skills[indexSkill].sections[indexSection].exercises[
                    indexExer
                  ].offset_question--;
                }
                for (
                  let indexSec = self.skills[indexSkill].sections.length - 1;
                  indexSec >= indexSection + 1;
                  indexSec--
                ) {
                  self.skills[indexSkill].sections[indexSec].offset_question--;
                }
                self.skills[indexSkill].sections[indexSection].total_question--;
                self.skills[indexSkill].sections[indexSection].exercises[
                  indexExercise
                ].total_question--;
                self.skills[indexSkill].sections[indexSection].exercises[
                  indexExercise
                ].questions.splice(indexQuestion, 1);
                self.$set(self.skills);
                self.is_call_api = false;
              }
            })
            .catch(function (error) {
              console.log(error);
              if (error) {
                self.is_call_api = false;
                self.messError("Thao tác không thành công. Vui lòng liên hệ phòng kỹ thuật hỗ trợ");
              }
            });
        }
      });
    },
    duplicateQuestion(indexSkill, indexSection, indexExercise, indexQuestion) {
      let vm = this;
      let dataQuestion =
        this.skills[indexSkill].sections[indexSection].exercises[indexExercise]
          .questions[indexQuestion];
      let optionAnswerSingleChoice = dataQuestion.answers[0].option;
      let correctSingleChoice = dataQuestion.answers[0].correct;
      let data = {
        exercise_id:
          this.skills[indexSkill].sections[indexSection].exercises[
            indexExercise
          ].id,
        exercise_type:
          this.skills[indexSkill].sections[indexSection].exercises[
            indexExercise
          ].exercise_type,
        title:
          this.skills[indexSkill].sections[indexSection].exercises[
            indexExercise
          ].questions[indexQuestion].title,
        description: "",
        validation_rules:
          this.skills[indexSkill].sections[indexSection].exercises[
            indexExercise
          ].questions[indexQuestion].validation_rules,
        answers: [
          {
            option: optionAnswerSingleChoice,
            correct: correctSingleChoice,
          },
        ],
      };
      vm.is_call_api = true;
      ApiService.post("prep-app/test/draft/question", data)
        .then(function (res) {
          if (res.status == 200) {
            let dataCheck =
              vm.skills[indexSkill].sections[indexSection].exercises[
                indexExercise
              ].questions;
            if (dataCheck == undefined || dataCheck == null) {
              vm.skills[indexSkill].sections[indexSection].exercises[
                indexExercise
              ].questions = [];
            }
            data.id = res.data.data.question_id;
            data.answer_id = res.data.data.answer_id;
            let indexRowQuestion =
              vm.skills[indexSkill].sections[indexSection].exercises[
                indexExercise
              ].questions.push(data) - 1;
            vm.$set(vm.skills);
            vm.is_call_api = false;
            setTimeout(() => {
              vm.$scrollTo(
                "#question" +
                  indexSkill +
                  indexSection +
                  indexExercise +
                  indexRowQuestion,
                1000
              );
            }, 100);
          }
        })
        .catch(function (error) {
          if (error) {
            vm.is_call_api = false;
            vm.messError("Có lỗi xảy ra. Vui lòng liên hệ bộ phận kỹ thuật hỗ trợ");
          }
        });
    },
    openDialogSortQuestion(indexSkill, indexSection, indexExercise) {
      this.indexSkill = indexSkill;
      this.indexSection = indexSection;
      this.indexExercise = indexExercise;
      this.dialogSortQuestion = true;
    },
    messError(msg = '') {
      this.$toasted.error(msg, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },
    displayQuestionType() {
      if (this.exercise.exercise_type === this.exercise_type_constant.UNIVERSAL) {
        return this.getLabelTypeByValueExercise(this.question.type);
      }
      return '';
    }
  },
};
</script>

<style></style>
