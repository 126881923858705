<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <v-btn
      rounded
      color="primary"
      class="mb-5"
      x-small
      large
      @click="openDiaLogTestPractice"
    >
      <v-icon color="white" dark>mdi-plus</v-icon
      ><span style="color: white">Tạo Test Set (Test Practice)</span>
    </v-btn>

    <v-btn
      rounded
      color="cyan"
      class="mb-5 ml-5"
      x-small
      large
      @click="openDialogSortTestPractice"
    >
      <v-icon color="white" dark>mdi-sort</v-icon
      ><span style="color: white">Sắp xếp test set</span>
    </v-btn>
    <div>
      <v-row>
        <v-col cols="12" class="d-flex pt-5">
          <label style="width: 7%" class="text-body-1 font-weight-medium mb-6"
            >Trang thái:</label
          >
          <v-radio-group class="mt-0 pt-0" v-model="status_filter" row>
            <v-radio label="Active" :value="status.ACTIVE"></v-radio>
            <v-radio label="Disabled" :value="status.DISABLED"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" class="d-flex pt-0">
          <label style="width: 7%" class="text-body-1 font-weight-medium mb-6"
            >Product Category:</label
          >
          <v-radio-group
            class="mt-0 pt-0"
            v-model="product_category_filter"
            row
          >
            <template v-for="(product_category, i) in product_categories">
              <v-radio
                :key="i"
                :label="product_category.name.vi"
                :value="product_category.id"
              ></v-radio>
            </template>
          </v-radio-group>
        </v-col>
        <v-col cols="12">
          <div class="table-responsive">
            <table
              class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
            >
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Ảnh</th>
                  <!--                <th scope="col">Là bộ đề hot</th>-->
                  <!--                <th scope="col">Là bộ đề đoán</th>-->
                  <th scope="col">Trạng thái</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(test_practice, i) in test_practices">
                  <tr :key="i">
                    <td scope="row">{{ i + 1 }}</td>
                    <td class="">
                      <p
                        class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >
                        {{ test_practice.name }}
                      </p>
                    </td>
                    <td>
                      <v-img
                        v-if="test_practice.avatar"
                        :src="test_practice.avatar"
                        max-width="100"
                        max-height="70"
                        class="rounded-lg"
                      ></v-img>
                    </td>
                    <!--                  <td>-->
                    <!--                    <p class="text-dark-75 font-weight-bolder font-size-lg">{{test_practice.hot === 1 ? 'Yes' : 'No'}}</p>-->
                    <!--                  </td>-->
                    <!--                  <td>-->
                    <!--                    <p class="text-dark-75 font-weight-bolder font-size-lg">{{test_practice.predict === 1 ? 'Yes' : 'No'}}</p>-->
                    <!--                  </td>-->
                    <td>
                      <p
                        class="text-dark-75 font-weight-bolder font-size-lg"
                        v-if="test_practice.status == status.DISABLED"
                      >
                        Disabled
                      </p>
                      <p
                        class="text-dark-75 font-weight-bolder font-size-lg"
                        v-if="test_practice.status == status.ACTIVE"
                      >
                        Active
                      </p>
                    </td>
                    <td>
                      <div style="display: flex">
                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <button
                              class="btn btn-icon btn-light-warning btn-sm"
                              v-bind="attrs"
                              v-on="on"
                              @click="editTestPractice(i)"
                            >
                              <span
                                class="svg-icon svg-icon-md svg-icon-primary"
                              >
                                <v-icon color=""
                                  >mdi-square-edit-outline</v-icon
                                >
                              </span>
                            </button>
                          </template>
                          <span>Edit</span>
                        </v-tooltip>
                        <v-tooltip top v-if="test_practice.status == status.ACTIVE">
                          <template v-slot:activator="{ on, attrs }">
                            <button
                                class="btn btn-icon btn-light-warning btn-sm ml-2"
                                v-bind="attrs"
                                v-on="on"
                                @click="openDialogDuplicateTestSet(test_practice)"
                            >
                                <span
                                    class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-content-copy</v-icon>
                                </span>
                            </button>
                          </template>
                          <span>Sao chép</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <router-link
                              :to="{
                                name: 'TestSetTest',
                                params: {
                                  id: test_practice.id,
                                  model: 'test-practice',
                                },
                              }"
                              target="_blank"
                            >
                              <button
                                class="btn btn-icon btn-light-success btn-sm ml-2"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-currency-eth</v-icon>
                                </span>
                              </button>
                            </router-link>
                          </template>
                          <span>Danh sách test</span>
                        </v-tooltip>
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <button
                              class="btn btn-icon btn-light-danger btn-sm ml-2"
                              v-bind="attrs"
                              v-on="on"
                              @click="deleteTestPractice(test_practice)"
                            >
                              <span
                                class="svg-icon svg-icon-md svg-icon-primary"
                              >
                                <v-icon color="">mdi-delete</v-icon>
                              </span>
                            </button>
                          </template>
                          <span>Delete</span>
                        </v-tooltip>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </v-col>
      </v-row>
    </div>

    <TestSetModal
      model_input="test_practice"
      :show_dialog="dialogTestSet"
      :type_form="type_form"
      :product_category_tags="product_categories"
      :test_set="test_practice"
      :markets="markets"
      :platforms="platforms"
      :tags_action="tags"
      @setDialog="(e) => (this.dialogTestSet = e)"
      @resetData="getAllTestPractice()"
    />

    <TestSetDuplicateForm
      :show_dialog="dialogDuplicateTestSet"
      :test_set="test_practice"
      @setDialog="(e) => (this.dialogDuplicateTestSet = e)"
      @resetData="getAllTestPractice()"
    />

    <v-row justify="center">
      <v-dialog
        v-model="dialogSortTestSet"
        scrollable
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Sort Lesson/ Test</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogSortTestSet = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <draggable :list="data_draggable">
                    <ul
                      class="list-unstyled drag-css cursor-pointer"
                      v-for="(testset, i) in data_draggable"
                      :key="i"
                    >
                      <b-media tag="li">
                        <h5 class="mt-0 mb-1" style="color: #3699ff">
                          {{ testset.name }}
                        </h5>
                      </b-media>
                    </ul>
                  </draggable>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" outlined @click="sortTestSet"
              >Update</v-btn
            >
            <v-btn color="red darken-1" text @click="dialogSortTestSet = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import TestSetModal from "./modal/TestSetModal";
import draggable from "vuedraggable";
import Swal from "sweetalert2";
import TestSetDuplicateForm from "./modal/TestSetDuplicateForm";

export default {
  name: "TestPracticeList",
  components: {
    TestSetDuplicateForm,
    TestSetModal,
    Loading: () => import("vue-loading-overlay"),
    draggable,
  },
  props: {
    product_categories: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      is_call_api: false,
      status: {
        DISABLED: 0,
        ACTIVE: 1,
      },
      test_practices: [],
      test_practice: {},
      status_filter: 1,
      dialogTestSet: false,
      type_form: null,
      data_draggable: [],
      dialogSortTestSet: false,
      tags: [],
      product_category_filter: 1,
      markets: [],
      dialogDuplicateTestSet: false,
      platforms: [],
    };
  },
  created() {
    this.getAllTestPractice();
    this.getAllMarket();
    this.getAllPlatform();
  },
  watch: {
    status_filter() {
      this.getAllTestPractice();
    },
    product_category_filter() {
      this.getAllTestPractice();
    },
  },
  methods: {
    async getAllTestPractice() {
      try {
        this.is_call_api = true;
        let url = "prep-app/test-set/list-test-practice?";
        url += url + "&status=" + this.status_filter;
        url += url + "&category_id=" + this.product_category_filter;

        let res = await ApiService.get(url);
        if (res.status === 200) {
          this.test_practices = res.data;
          this.is_call_api = false;
        }
      } catch (error) {
        this.is_call_api = false;
        console.log(error);
      }
    },
    async getAllMarket() {
      let vm = this;
      try {
        let url = "prep-app/market/limit?";

        let res = await ApiService.get(url);
        if (res.status === 200) {
          vm.markets = res.data;
        }
      } catch (error) {
        console.log(error);
        vm.messError('Lỗi không lấy được dữ liệu thị trường');
      }
    },
    async getAllPlatform() {
      let vm = this;
      try {
        let url = "prep-app/test-set/platform?";

        let res = await ApiService.get(url);
        if (res.status === 200) {
          vm.platforms = res.data;
        }
      } catch (error) {
        console.log(error);
        vm.messError('Lỗi không lấy được dữ liệu platform');
      }
    },
    openDiaLogTestPractice() {
      this.type_form = "add";
      this.dialogTestSet = true;
    },
    editTestPractice(index) {
      this.test_practice = this.test_practices[index];
      this.getTagsById(this.test_practice.id);
      this.type_form = "update";
      this.dialogTestSet = true;
    },
    deleteTestPractice(test_set) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          let data = {
            product_category_id: test_set.category_id,
          }
          ApiService.delete("prep-app/test-set/" + test_set.id, data)
            .then(function (res) {
              if (res.status === 200) {
                vm.successMess("Xóa thành công !");
                vm.getAllTestPractice();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    openDialogSortTestPractice() {
      this.data_draggable = JSON.parse(JSON.stringify(this.test_practices));
      this.dialogSortTestSet = true;
    },
    sortTestSet() {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want save?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          let data_sort = this.data_draggable.map((item, index) => {
            return {
              test_set_id: item.id,
              offset: index + 1,
            };
          });
          let data = {
            data_sort: data_sort,
          };
          vm.is_call_api = true;
          ApiService.post("prep-app/test-set/sort", data)
            .then(function (res) {
              if (res.status === 200) {
                vm.getAllTestPractice();
                vm.successMess("Sắp xếp thành công !");
                vm.is_call_api = false;
                vm.dialogSortTestSet = false;
              }
            })
            .catch(function (error) {
              vm.is_call_api = false;
              console.log(error);
            });
        }
      });
    },
    async getTagsById(test_set_id) {
      try {
        this.is_call_api = true;
        let url = "prep-app/test-set/tags-test-practice/" + test_set_id;

        let res = await ApiService.get(url);
        if (res.status === 200) {
          this.tags = res.data;
          this.is_call_api = false;
        }
      } catch (error) {
        this.is_call_api = false;
        console.log(error);
      }
    },
    errorMess(mess = 'Có lỗi xảy ra') {
      this.$toasted.error(mess, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },
    successMess(mess = 'Thành công') {
      this.$toasted.success(mess, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },
    openDialogDuplicateTestSet(test_set) {
      this.dialogDuplicateTestSet = true;
      this.test_practice = JSON.parse(JSON.stringify(test_set));
    },
  },
};
</script>

<style lang="css" scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.table td {
  vertical-align: middle;
}
.btn.btn-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
  border-color: transparent;
}
.btn.btn-light-success i {
  color: #3699ff;
}
.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #3699ff;
}
</style>
