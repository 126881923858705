<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">
        {{ courseName == null ? "Course content" : courseName }}
      </h1>
    </div>
    <div class="pt-5 pl-5 pr-5">
      <router-link
        :to="{
          name: 'TestBuilderForm',
          params: {
            courseId: id_course,
            type_form_test: 'add',
            is_free: 0,
            offset: course_items.length + 1,
          },
        }"
      >
        <v-btn rounded color="primary" class="mb-5" x-small large>
          <v-icon color="white" dark>mdi-plus</v-icon
          ><span style="color: white">Create Lesson / Test</span>
        </v-btn>
      </router-link>
      <v-btn
        rounded
        color="cyan"
        class="mb-5 ml-5"
        x-small
        large
        @click="openDialogSortLessTest"
      >
        <v-icon color="white" dark>mdi-sort</v-icon
        ><span style="color: white">Sort Lesson/ Test</span>
      </v-btn>
      <v-btn
        rounded
        color="purple"
        class="mb-5 ml-5"
        x-small
        large
        @click="openDialogAddLessTest"
      >
        <v-icon color="white" dark>mdi-file</v-icon
        ><span style="color: white">Add Existing Lesson / Test</span>
      </v-btn>
      <div>
        <!-- <v-pagination
          class="mt-5"
          style="float: right"
          :length="3"
          :total-visible="0"
        ></v-pagination> -->
        <div class="table-responsive">
          <table
            class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
          >
            <thead class="thead-ipp">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Status</th>
                <th scope="col">Type</th>
                <th scope="col">Free</th>
                <th scope="col">Time</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(course_item, i) in course_items">
                <tr :key="i">
                  <!-- <th scope="row">{{i+1}}</th> -->
                  <th scope="row">
                    {{ course_item.type === 1 ? "Lesson" : "Test" }}
                    {{ course_item.indexLessTest }}
                  </th>
                  <td v-if="course_item.test">
                    <span class="text-dark-75 font-weight-bolder font-size-lg">{{ course_item.test.title }}</span>
                    <span v-if="isTestVsr(course_item.test)" class="p-2 mx-2 font-weight-bold deep-purple lighten-4 deep-purple--text rounded-lg">VSR</span>
                    <span v-if="isTestVwr(course_item.test)" class="p-2 mx-2 font-weight-bold purple lighten-4 purple--text rounded-lg">VWR</span>
                  </td>
                  <td>
                    <span
                      class="label label-lg label-inline font-weight-bold py-4"
                      v-bind:class="
                        course_item.is_locked === 0
                          ? 'label-light-success'
                          : 'label-light-warning'
                      "
                    >
                      {{ course_item.is_locked === 0 ? "Unlock" : "Locked" }}
                    </span>
                  </td>
                  <td>
                    <span
                      class="label label-lg label-inline font-weight-bold py-4"
                      v-bind:class="
                        course_item.type === 1
                          ? 'label-light-primary'
                          : 'label-light-danger'
                      "
                    >
                      {{ course_item.type === 1 ? "Lesson" : "Test" }}
                    </span>
                  </td>
                  <td>
                    <span
                      class="label label-lg label-inline font-weight-bold py-4"
                      v-bind:class="
                        course_item.free === 1
                          ? 'label-light-warning'
                          : 'label-light-success'
                      "
                    >
                      {{ course_item.free === 1 ? "Free" : "Paid" }}
                    </span>
                  </td>
                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >{{ course_item.duration | convertSecondToHM }}</span
                    >
                  </td>
                  <td style="display: flex">
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <button
                          class="btn btn-icon btn-light-success btn-sm"
                          v-bind="attrs"
                          v-on="on"
                          @click="editCourseItem(course_item)"
                        >
                          <span class="svg-icon svg-icon-md svg-icon-primary">
                            <v-icon color="">mdi-square-edit-outline</v-icon>
                          </span>
                        </button>
                      </template>
                      <span>Edit Course Item</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <button
                          class="btn btn-icon btn-light-danger btn-sm ml-4"
                          v-bind="attrs"
                          v-on="on"
                          @click="deleteCourseItem(course_item)"
                        >
                          <span class="svg-icon svg-icon-md svg-icon-primary">
                            <v-icon color="">mdi-delete</v-icon>
                          </span>
                        </button>
                      </template>
                      <span>Delete Course Item</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <button
                          class="btn btn-icon btn-light-primary btn-sm ml-4"
                          v-bind="attrs"
                          v-on="on"
                          @click="previewLessTest(course_item.test.id)"
                        >
                          <span class="svg-icon svg-icon-md svg-icon-primary">
                            <v-icon color="">mdi-file-find</v-icon>
                          </span>
                        </button>
                      </template>
                      <span>Preview Test</span>
                    </v-tooltip>
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <router-link
                          :to="{
                            name: 'EditTest',
                            params: {
                              id: course_item.test.id,
                              courseId: id_course,
                              type_form_test: 'edit',
                              is_free: course_item.free,
                              offset: course_item.offset,
                            },
                          }"
                        >
                          <button
                            class="btn btn-icon btn-light-warning btn-sm ml-4"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <span class="svg-icon svg-icon-md svg-icon-primary">
                              <v-icon color="">mdi-chevron-triple-right</v-icon>
                            </span>
                          </button>
                        </router-link>
                      </template>
                      <span>Edit Test</span>
                    </v-tooltip>
                  </td>
                </tr>
              </template>
              <template v-if="!course_items.length">
                <tr>
                  <td style="text-align: center" colspan="6">No data</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <!-- <v-pagination
          style="float: right"
          :length="3"
          :total-visible="0"
        ></v-pagination> -->
      </div>
    </div>
    <v-row justify="center">
      <v-dialog v-model="dialogAddLessTest" scrollable max-width="1000px">
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">
                <template v-if="type_form_course_item === 'add'">Add Lesson/ Test</template>
                <template v-if="type_form_course_item === 'update'">Edit Lesson/ Test</template>
              </span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogAddLessTest = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="7" class="pt-0 pb-0">
                  <label style="font-size: 16px">Type: </label>
                  <v-radio-group v-model="type_input" row class="mb-0 pb-0">
                    <v-radio label="Lesson" :value="unit_type_constant.Lesson"></v-radio>
                    <v-radio label="Test" :value="unit_type_constant.Test"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="7" class="pb-0">
                  <div class="d-flex">
                    <label style="font-size: 16px">Free: </label>
                    <v-switch
                      class="mt-0 pt-0 ml-2"
                      v-model="free_input"
                      :label="`${free_input.toString()}`"
                    ></v-switch>
                  </div>
                </v-col>
                <v-col cols="12" class="m-0 p-0"></v-col>
                <v-col cols="12" class="d-flex">
                  <label style="font-size: 16px" class="mt-1 p-0"
                    >Thời gian (giờ:phút):
                  </label>
                  <date-picker
                    class="ml-2"
                    v-model="duration_input"
                    type="time"
                    format="HH:mm"
                    value-type="format"
                    placeholder="hh:mm"
                  ></date-picker>
                </v-col>
                <v-col cols="12" class="m-0 p-0"></v-col>
                <v-col cols="6" class="d-flex pb-0">
                  <label style="font-size: 16px" class="mt-1 p-0"
                    >Locked:
                  </label>
                  <v-switch
                    class="mt-0 pt-0 ml-2"
                    v-model="is_locked"
                    :label="`${is_locked.toString().toUpperCase()}`"
                  ></v-switch>
                </v-col>
                <v-col cols="6" class="d-flex pb-0" v-if="is_locked === true">
                  <label style="font-size: 16px" class="mt-2 p-0"
                    >Unlock at:
                  </label>
                  <date-picker
                    class="ml-2"
                    v-model="unlock_at_input"
                    value-type="format"
                    format="YYYY-MM-DD"
                  />
                </v-col>
                <v-col cols="12" class="pb-0 pt-0">
                  <p class="red--text">
                    ( nếu muốn lock thì hãy chọn ngày unlock )
                  </p>
                </v-col>
                <v-col cols="6" class="pb-0 d-flex">
                  <label style="font-size: 16px" class="mt-1 p-0">Compulsory:</label>
                  <v-switch
                      class="mt-0 pt-0 ml-2"
                      v-model="compulsory"
                      :label="`${compulsory.toString().toUpperCase()}`"
                  ></v-switch>
                </v-col>
                <!-- <v-col cols="7" class="pt-0 pb-0">
                  <v-text-field v-model="offset_input" label="Offset" disabled min="0" max="9999" maxlength="4" oninput="this.value=this.value.slice(0,this.maxLength||1/1);this.value=(this.value   < 0) ? (0/0) : this.value;" type="number" required></v-text-field>
                </v-col> -->
                <v-col cols="8" class="pb-0 pt-2 d-flex">
                  <label style="font-size: 16px" class="mt-2 p-0 pr-2"
                    >Survey:
                  </label>
                  <v-autocomplete
                    :items="surveys"
                    item-text="name"
                    item-value="id"
                    v-model="survey_id"
                    chips
                    solo
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <label>
                    <span style="font-size: 16px">Choose Test</span>
                    <span class="text-h6 ml-2" v-if="inputedTest && Object.keys(inputedTest).length">
                      (
                        <span class="font-weight-black">Unit đã chọn:</span>
                        <span class="ml-1">#{{inputedTest.id}} - {{inputedTest.title}}</span>
                      )
                    </span>
                  </label>
                  <div>
                    <div style="float: left; width: 250px">
                      <b-form-input
                        class="mt-0"
                        style="width: 100%"
                        placeholder="Search"
                        v-model="search_test_input"
                        @keyup.enter="enterSearchTest()"
                      ></b-form-input>
                      <span
                        style="clear: both; display: inline-block"
                        class="text-subtitle-1 grey--text darken-1"
                        >(Enter để tìm kiếm)</span
                      >
                    </div>
                    <v-pagination
                      style="float: right"
                      color="#00BCD4"
                      v-model="data_pagination_test.current_page"
                      :length="data_pagination_test.total_pages"
                      :total-visible="6"
                      @input="getAllUnit"
                    ></v-pagination>
                    <div class="table-responsive" style="overflow: hidden">
                      <table
                        class="table table-bordered table-head-custom table-vertical-center table-head-bg"
                      >
                        <thead class="thead-ipp">
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Title</th>
                            <th scope="col">Limit time</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="(test, i) in tests">
                            <tr :key="i">
                              <!-- <th scope="row">{{i+1}}</th> -->
                              <td>
                                <v-radio-group
                                  v-model="test_input"
                                  class="mt-0 mb-0 pt-0"
                                  style="max-height: 25px"
                                >
                                  <v-radio label="" :value="test.id"></v-radio>
                                </v-radio-group>
                              </td>
                              <td>
                                <span class="text-dark-75 font-weight-normal font-size-lg">#{{ test.id }} - {{ test.title }}</span>
                                <span v-if="isTestVsr(test)" class="p-2 mx-2 font-weight-bold deep-purple lighten-4 deep-purple--text rounded-lg">VSR</span>
                                <span v-if="isTestVwr(test)" class="p-2 mx-2 font-weight-bold purple lighten-4 purple--text rounded-lg">VWR</span>
                              </td>
                              <td>
                                <span
                                  class="label label-lg label-inline font-weight-bold py-4"
                                  v-bind:class="
                                    test.is_limit_time == 1
                                      ? 'label-light-success'
                                      : 'label-light-warning'
                                  "
                                >
                                  {{ test.is_limit_time == 1 ? "Yes" : "No" }}
                                </span>
                              </td>
                            </tr>
                          </template>
                          <template v-if="tests.length <= 0">
                            <tr>
                              <td style="text-align: center" colspan="3">
                                No data
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                    <v-pagination
                      style="float: right"
                      color="#00BCD4"
                      v-model="data_pagination_test.current_page"
                      :length="data_pagination_test.total_pages"
                      :total-visible="6"
                    ></v-pagination>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="dialogAddLessTest = false">Close</v-btn>
            <v-btn
              color="blue" class="font-weight-bold"
              outlined
              v-if="type_form_course_item === 'add'"
              @click="btnAddOrEditLessTest(1)"
              >Add
            </v-btn>
            <v-btn
              color="blue" class="font-weight-bold"
              outlined
              v-if="type_form_course_item === 'update'"
              @click="btnAddOrEditLessTest(2)"
              >Update
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog
        v-model="dialogSortLessTest"
        scrollable
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Sort Lesson/ Test</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogSortLessTest = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <draggable :list="data_draggable">
                    <ul
                      class="list-unstyled drag-css"
                      v-for="(course_item, i) in data_draggable"
                      :key="i"
                    >
                      <b-media tag="li">
                        <h5
                          class="mt-0 mb-1"
                          :style="{
                            color:
                              course_item.type == 1 ? '#3699ff' : '#6993ff',
                          }"
                        >
                          {{ course_item.type == 1 ? "Lesson" : "Test" }}
                        </h5>
                        <p class="mb-0">
                          {{ course_item.test.title }}
                        </p>
                      </b-media>
                    </ul>
                  </draggable>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="sortCourseItems"
              >Update</v-btn
            >
            <v-btn
              color="blue darken-1"
              text
              @click="dialogSortLessTest = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialogPreviewTest" scrollable>
        <v-card>
          <v-card-title class="headline grey lighten-2">
            <v-col cols="11">
              <span class="headline">Preview Test</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogPreviewTest = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text class="dialog-preview-test">
            <!-- <PreviewLessTest :dataTest="object_test"></PreviewLessTest> -->
            <!-- <b-embed type="iframe" aspect="16by9" :src="location_origin+'/preview-test/dist/index.html?test_id='+preview_test_id" ></b-embed> -->
            <iframe
              :src="
                location_origin +
                '/preview-test/dist/index.html?test_id=' +
                preview_test_id
              "
              style="height: 100%; width: 100%"
              frameborder="0"
            ></iframe>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogPreviewTest = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import _ from "lodash";
import Swal from "sweetalert2";
import draggable from "vuedraggable";
import ApiService from "@/service/api.service";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import "vue-loading-overlay/dist/vue-loading.css";
import constantTestBuilder from "../tests/mixins/Support/constantTestBuilder";

export default {
  name: "CourseContent",
  mixins: [validationMixin, constantTestBuilder],
  components: {
    Loading: () => import("vue-loading-overlay"),
    draggable,
    // PreviewLessTest: () => import('./PreviewTest/PreviewLessTest'),
  },
  props: {
    dataTest: {
      type: Object,
      default: function () {
        return {};
      },
    },
    // courseName: {
    //   type: String,
    //   default: null
    // }
  },
  data() {
    return {
      is_call_api: false,
      dialogAddLessTest: false,
      dialogSortLessTest: false,
      dialogPreviewTest: false,
      type_form_course_item: null,
      type_input: "Lesson",
      unit_type_constant: {
        Lesson: 'Lesson',
        Test: 'Test',
      },
      test_input: null,
      offset_input: 0,
      free_input: true,
      course_items: [],
      optionFree: ["Yes", "No"],
      object_test: {},
      preview_test_id: null,
      tests: [],
      search_test_input: "",
      data_pagination_test: {
        current_page: 0,
        total_pages: 1,
      },
      course_item_id: null,
      data_draggable: [],
      duration_input: "00:00",
      is_locked: false,
      unlock_at_input: null,
      courseName: "",
      surveys: [],
      survey_id: null,
      inputedTest: null,
      compulsory: false,
    };
  },
  validations: {
    offset_input: {
      required,
    },
  },
  computed: {
    id_course: function () {
      return this.$route.params.id;
    },
    location_origin: {
      get() {
        return window.location.origin;
      },
    },
    list_unit_url: function () {
      let url = '';
      if (this.type_input === this.unit_type_constant.Lesson) {
        url = 'prep-app/test/lesson-unit?';
      } else if (this.type_input === this.unit_type_constant.Test) {
        url = 'prep-app/test/test-unit?';
      }
      url += 'model=course_item'
      return url;

    },
  },
  created() {
    this.is_call_api = true;
    this.getAllUnit();
    this.checkDataTest();
    // this.getAllCourseItem();
    this.getAllSurvey();
    this.is_call_api = false;
  },
  watch: {
    "data_pagination_test.current_page": function (page) {
      this.test_input = null;
      this.actionSearchTest(page, this.checkQuerySearchTest(this.search_test_input));
    },
    // search_test_input: function(val) {
    //   this.actionSearchTest(1, val);
    // }
    type_input: async function () {
      this.is_call_api = true;
      await this.getAllUnit();
      this.is_call_api = false;
    }
  },
  methods: {
    checkDataTest() {
      // đoạn này check xem có dataTest từ prop truyền vào không, nếu có thì add vào d.sách course_item nếu không thì load d.sách course_item
      if (Object.keys(this.dataTest).length > 0) {
        this.type_input = this.dataTest.type_expand == 1 ? this.unit_type_constant.Lesson : this.unit_type_constant.Test;
        this.free_input = this.dataTest.is_free;
        this.test_input = this.dataTest.id;
        this.offset_input = this.dataTest.offset;
        this.duration_input = this.dataTest.duration == null ? "00:00" : this.$options.filters.convertSecondToHM(this.dataTest.duration);
        this.btnAddOrEditLessTest(1);
      } else {
        this.getAllCourseItem();
      }
    },
    async getAllCourseItem() {
      let vm = this;
      await ApiService.get("prep-app/courses/" + this.id_course + "/items").then(function (response) {
        if (response.status === 200) {
          vm.course_items = vm.setIndexLessTest(response.data.data_item);
          vm.courseName = response.data.course_name.vi;
        }
      });
    },
    async getAllUnit(page = 1) {
      let self = this;
      await ApiService.get(this.list_unit_url + "&page=" + page)
      .then(function (response) {
        if (response.status === 200) {
          self.tests = response.data.data;
          self.data_pagination_test = response.data.meta.pagination;
        }
      });
    },
    setIndexLessTest(course_items) {
      let indexLess = 0,
        indexTest = 0;
      course_items.forEach((element) => {
        if (element.type === 1) {
          indexLess++;
          element.indexLessTest = indexLess;
        } else {
          indexTest++;
          element.indexLessTest = indexTest;
        }
      });
      return course_items;
    },
    actionSearchTest(page = 1, query = "") {
      let vm = this;
      vm.is_call_api = true;
      let listUnitRestApi = _.debounce(function () {
        ApiService.get(vm.list_unit_url+"&page=" + page + "&name=" + query)
          .then(function (response) {
              if (response.status === 200) {
                vm.is_call_api = false;
                vm.tests = response.data.data;
                vm.data_pagination_test = response.data.meta.pagination;
              }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            console.log(error);
          });
      }, 1200);

      listUnitRestApi();
    },
    openDialogAddLessTest() {
      this.dialogAddLessTest = true;
      this.type_form_course_item = "add";
      this.type_input = "Lesson";
      this.test_input = null;
      this.free_input = false;
      this.offset_input = this.course_items.length + 1;
      this.duration_input = "00:00";
      this.is_locked = false;
      this.unlock_at_input = null;
      this.survey_id = null;
      this.inputedTest = null;
    },
    editCourseItem(course_item) {
      course_item = JSON.parse(JSON.stringify(course_item));
      this.dialogAddLessTest = true;
      this.type_form_course_item = "update";
      this.type_input = course_item.type === 1 ? "Lesson" : "Test";
      this.free_input = course_item.free === 1;
      this.offset_input = course_item.offset;
      this.test_input = course_item.test.id;
      this.course_item_id = course_item.id;
      this.duration_input = this.$options.filters.convertSecondToHM(
        course_item.duration
      );
      this.is_locked = course_item.is_locked === 1;
      this.unlock_at_input = course_item.is_locked === 1 ? this.$options.filters.convertTimestampToDate(course_item.unlocked_at) : null;
      this.survey_id = course_item.survey_id;
      this.inputedTest = course_item.test ?? null;
      this.compulsory = course_item.compulsory === 1;
    },
    async btnAddOrEditLessTest(type) {
      let self = this;
      let data = {
        item_id: this.test_input,
        item_type: this.type_input == "Lesson" ? 1 : 2,
        free: this.free_input == false ? 0 : 1,
        offset: this.offset_input,
        duration: this.$options.filters.convertHMToSecond(this.duration_input),
        is_locked: this.is_locked == true ? 1 : 0,
        survey_id: this.survey_id,
        compulsory: this.compulsory ? 1 : 0,
      };
      if (this.unlock_at_input != null) {
        data.unlocked_at = this.unlock_at_input;
      }
      self.is_call_api = true;
      if (type === 1) {
        await ApiService.post("prep-app/courses/" + this.id_course + "/items", data)
          .then(function (res) {
            if (res.status === 201) {
              self.is_call_api = false;
              self.getAllCourseItem();
              self.messSuccessSwal("Đã thêm bài test vào course thành công");
            }
          })
          .catch(function (error) {
            if (error?.response?.status === 422) {
              let data_response_error = error.response.data.error.message.split(".<br />");
              self.showError(data_response_error);
            } else {
              self.messError("Something Wrong. Please try again or contact admin !!");
            }
            self.is_call_api = false;
          });
      } else {
        await ApiService.put("prep-app/courses/" + this.id_course + "/items/" + this.course_item_id, data)
          .then(function (res) {
            if (res.status === 201) {
              self.is_call_api = false;
              self.getAllCourseItem();
              self.messSuccessSwal("Sửa course item thành công!");
            }
          })
          .catch(function (error) {
            if (error?.response?.status === 422) {
              let data_response_error = error.response.data.error.message.split(".<br />");
              self.showError(data_response_error);
            } else {
              self.messError("Something Wrong. Please try again or contact admin !!");
            }
            self.is_call_api = false;
          });
      }
      this.dialogAddLessTest = false;
      // }
    },
    messSuccessSwal(mess = '') {
      Swal.fire({
        title: "",
        text: mess,
        icon: "success",
        confirmButtonClass: "btn btn-secondary",
      });
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.messError(data_error[i]);
      }
    },
    messError(mess = '') {
      this.$toasted.error(mess, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },
    deleteCourseItem(course_item) {
      let self = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          let data = {
            product_category_id: course_item.test.product_category_id,
          };
          ApiService.delete("prep-app/courses/" + this.id_course + "/items/" + course_item.id, data)
            .then(function (res) {
              if (res.status == 202) {
                self.getAllCourseItem();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    openDialogSortLessTest() {
      this.data_draggable = [...this.course_items];
      this.dialogSortLessTest = true;
    },
    sortCourseItems() {
      let self = this;
      Swal.fire({
        title: "Are you sure you want save?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          let items = this.data_draggable.map((course_item, index) => {
            return {
              item_id: course_item.id,
              offset: index + 1,
            };
          });
          let data = {
            items: items,
          };
          ApiService.put(
            "prep-app/courses/" + this.id_course + "/items/sorting",
            data
          )
            .then(function (res) {
              if (res.status == 202) {
                self.getAllCourseItem();
                Swal.fire({
                  title: "",
                  text: "The application has been successfully submitted!",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary",
                });
                self.dialogSortLessTest = false;
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    async previewLessTest(id_test) {
      this.preview_test_id = id_test;
      this.dialogPreviewTest = true;
    },
    async getAllSurvey() {
      let vm = this;
      await ApiService.get("prep-app/survey/limit").then(function (response) {
        if (response.status === 200) {
          vm.surveys = response.data;
        }
      });
    },
    enterSearchTest() {
      let vm = this;
      // vm.is_call_api = true;
      ApiService.get(
        this.list_unit_url+"&page=" +
          1 +
          "&name=" +
          this.checkQuerySearchTest(this.search_test_input)
      ).then(function (response) {
        if (response.status === 200) {
          vm.tests = response.data.data;
          vm.data_pagination_test = response.data.meta.pagination;
          // vm.is_call_api = false;
        }
      });
    },
    checkQuerySearchTest(query) {
      if (query.substring(0, 1) === "#") {
        return "id-" + query.substring(1);
      }
      return query;
    },
    isTestVsr(test) {
      if (test.skill_mapping_records) {
        let speakingSkill = test.skill_mapping_records.find((skill) => skill.child_id === this.skill_constant.SPEAKING);
        if (speakingSkill) {
          return speakingSkill.vsr === 1;
        }

      }
      return false;
    },
    isTestVwr(test) {
      if (test.skill_mapping_records) {
        let speakingSkill = test.skill_mapping_records.find((skill) => skill.child_id === this.skill_constant.WRITING);
        if (speakingSkill) {
          return speakingSkill.vwr === 1;
        }

      }
      return false;
    },
  },
};
</script>

<style lang="css" scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.thead-ipp {
  background-color: #136495;
  color: white;
}
.error-validation {
  color: red;
}
.v-messages {
  min-height: 0px !important;
}
.drag-css {
  cursor: pointer;
}
.label.label-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
}
.btn.btn-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
  border-color: transparent;
}
.btn.btn-light-success i {
  color: #3699ff;
}
.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #3699ff;
}
.dialog-preview-test {
  overflow: hidden !important;
  height: 10000px !important;
}
</style>
