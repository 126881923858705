import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import markingTicketAnswerStore from "./modules/markingTicketAnswerStore";
import informationTestBuilderStore from "./modules/test-builder/informationTestBuilderStore";
import contentTestBuilderStore from "./modules/test-builder/contentTestBuilderStore";
import entryTestStore from "./modules/entryTestStore";
import prepChatGroup from "../../../plugins/prepChat/store/prepChatGroup";
import prepChatUser from "../../../plugins/prepChat/store/prepChatUser";
import surveyQuestionStore from "./modules/surveyQuestionStore";
import interactiveVideoStore from "./modules/test-builder/interactiveVideoStore";
import sectionPartConfig from "./modules/test-builder/sectionPartConfig";
import exerciseConfigStore from "./modules/test-builder/exerciseConfigStore";
import constantSupportForUnitStore from "./modules/test-builder/constantSupportForUnitStore";
import elementStore from "./modules/test-builder/elementStore";

Vue.use(Vuex);
export default new Vuex.Store({
  state: {},
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    markingTicketAnswerStore,
    informationTestBuilderStore,
    contentTestBuilderStore,
    entryTestStore,
    prepChatGroup,
    prepChatUser,
    surveyQuestionStore,
    interactiveVideoStore,
    sectionPartConfig,
    exerciseConfigStore,
    constantSupportForUnitStore,
    elementStore,
  },
});
