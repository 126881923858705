<template>
  <div style="width: 100%">
    <div style="width: 100%">
      <v-tooltip bottom color="#181c32f0">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              @click="getPinyinFromChineseTextSelectedToRefInputEditor('refContentQuestionUniversal')"
              class="mr-2 font-weight-bold"
              color="blue darken-1"
              outlined
              v-bind="attrs" v-on="on"
          >Thêm phiên âm
          </v-btn>
        </template>
        <div style="max-width: 500px; color: white">
          Thầy cô vui lòng bổ sung dấu cách giữa các từ có nghĩa trước khi thêm phiên âm để đảm bảo phiên âm hiển thị đúng vị trí các từ. Sau khi thêm phiên âm, thầy cô có thể điều chỉnh pinyin bằng cách edit nội dung pinyin giữa các thẻ &lt;/rt&gt;
        </div>
      </v-tooltip>
      <v-btn color="blue darken-1" outlined @click="openDialogAddOptionSentenceCompletion()" class="font-weight-bold">
        <v-icon>mdi-plus</v-icon> Thêm khoảng trống ( mô tả )
      </v-btn>

      <div style="width: 100%" class="pt-8">
        <p class="label_default ">Từ nhiễu</p>

        <template v-for="(obfuscate, i) in dataObfuscateSentenceCompletion">
          <div :key="i" style="width: 100%" class="d-flex">
            <v-text-field
                :ref="'sentence-completion-obfuscate-'+i"
                style="width: 60%"
                outlined dense
                class="mt-0"
                label="Từ nhiễu"
                v-model="obfuscate.value"
            ></v-text-field>
            <v-tooltip bottom color="#181c32f0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    @click="replacePinyinByTextSelectedViaInputOptionRef('sentence-completion-obfuscate-'+i, i)"
                    v-if="hasConfigPhonetic"
                    v-bind="attrs" v-on="on"
                    color="blue" small outlined class="ml-1">
                  Lấy pinyin
                </v-btn>
              </template>
              <div style="max-width: 500px; color: white">
                Thầy cô vui lòng bổ sung dấu cách giữa các từ có nghĩa trước khi thêm phiên âm để đảm bảo phiên âm hiển thị đúng vị trí các từ. Sau khi thêm phiên âm, thầy cô có thể điều chỉnh pinyin bằng cách edit nội dung pinyin giữa các thẻ &lt;/rt&gt;
              </div>
            </v-tooltip>
            <v-btn color="red" small outlined @click="deleteObfuscateSentenceCompletion(i)" class="ml-1">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <div style="width: 35%; border: 1px solid grey" class="ml-1 rounded" v-if="hasConfigPhonetic">
              <p class="subtitle-1 mb-0 border-bottom pl-1" style="background-color: #8080806e">Preview</p>
              <div
                  class="rounded px-1 py-2 container-pinyin-preview"
                  v-html="obfuscate.value">
              </div>
            </div>
          </div>
        </template>

        <div style="width: 180px" class="d-flex cursor-pointer" @click="addObfuscateSentenceCompletion()">
          <v-btn outlined icon color="sliver">
            <v-icon color="sliver">mdi-plus</v-icon>
          </v-btn>
          <span class="text-h6 mt-1 ml-1">Thêm từ nhiễu</span>
        </div>

      </div>
    </div>

    <v-row justify="center">
      <v-dialog
          v-model="dialogSentenceCompletion"
          persistent
          max-width="600px"
          scrollable
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <p class="headline mb-0">Thêm khoảng trống</p>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogSentenceCompletion = false" right
              >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <p class="text-body-1 red--text font-weight-medium">Nếu khoảng trống có nhiều hơn 1 đáp án đúng, vui lòng thêm đầy đủ các đáp án</p>
              <v-row>
                <v-col cols="12">
                  <template v-for="(correct, i) in optionSentenceCompletion">
                    <div :key="i" class="d-flex">
                      <v-text-field
                          :label="'Correct ' + (i + 1)"
                          :value="correct"
                          @change="changeCorrectValueSentenceCompletion($event, i)"
                      ></v-text-field>
                      <v-btn class="mt-3" @click="deleteCorrectSentenceCompletion(i)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </div>
                  </template>
                </v-col>
                <v-col cols="3">
                  <v-btn large @click="addCorrectSentenceCompletion()">
                    <v-icon>mdi-plus</v-icon> Add Correct
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="dialogSentenceCompletion = false">Close</v-btn>
            <v-btn color="blue darken-1" outlined @click="btnSaveCorrectSentenceCompletion()">Add</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import phoneticSupport from "../Support/Question/phoneticSupport";

export default {
  name: "SentenceCompeletion",
  mixins: [
    phoneticSupport
  ],
  props: {
    contentQuestionProp: {
      type: String,
      default: '',
    },
    refContentQuestionUniversal: {
      type: Object,
      default: () => {},
    },
    refContentPhoneticQuestionUniversal: {
      type: Object,
      default: () => {},
    },
    corrects: {
      type: Array,
      default: () => [],
    },
    obfuscates: {
      type: Array,
      default: () => [{ uuid: null, value: null, offset: 1 }],
    },
    hasConfigPhonetic: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogSentenceCompletion: false,
      optionSentenceCompletion: [''],
    };
  },
  computed: {
    dataCorrectSentenceCompletion: {
      get() {
        return this.corrects;
      },
      set(value) {
        this.$emit("setDataCorrectSentenceCompletion", value);
      },
    },
    dataObfuscateSentenceCompletion: {
      get() {
        return this.obfuscates;
      },
      set(value) {
        this.$emit("setDataObfuscateSentenceCompletion", value);
      },
    },
    contentQuestionInput: {
      get() {
        return this.contentQuestionProp;
      },
      set(value) {
        this.$emit("setContentQuestionProp", value);
      },
    },
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
  },
  watch: {

  },
  methods: {
    messError(mess) {
      this.$toasted.error(mess, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },
    openDialogAddOptionSentenceCompletion() {
      this.optionSentenceCompletion = [''];
      this.dialogSentenceCompletion = true;
    },
    addCorrectSentenceCompletion() {
      this.optionSentenceCompletion.push('');
    },
    validateCorrectSentenceCompletion() {
      let flat = true;
      for (let i = 0; i < this.optionSentenceCompletion.length; i++) {
        if (this.optionSentenceCompletion[i] === "") {
          this.messError("Hãy nhập thông tin cho option " + (i + 1) + " !!");

          flat = false;
        }
      }
      return flat;
    },
    createInstanceRefContentQuestionInput() {
      // Lý do có hàm này là bởi vì khi vào component này nếu contentQuestionInput === '' thì dữ liệu refContentQuestionUniversal đang bị undefined
      // Chú ý hàm này chủ yếu là check or create REF contentQuestionUniversal
      if (this.$utils.isValueInvalid(this.contentQuestionInput)) {
        this.contentQuestionInput = 'aaaa';
        this.contentQuestionInput = '';
        setTimeout(() => {
          // Lý do có setTimeout là bởi vì js chưa kịp render ra refContentQuestionUniversal thì để đã kết thúc hàm này
          console.log("oke");
        }, 500);
      } else  {
        this.messError("Có lỗi ! Hãy báo bên kỹ thuật");
      }
    },

    async btnSaveCorrectSentenceCompletion() {
      let vm = this;
      let refDescription = await vm.getRefContentQuestionUniversal();
      let validate = this.validateCorrectSentenceCompletion();
      if (!validate) {
        return;
      }
      let insertFirst = "{{";
      let insertLast = "}}";

      let refCkEditor = refDescription.$refs.editorCustom.$_instance;

      let data_add_option = " " + insertFirst + this.chainCorrectSentenceCompletion(this.optionSentenceCompletion) + insertLast;

      refCkEditor.model.change((writer) => {
        let insertPosition = refCkEditor.model.document.selection.getFirstPosition();
        writer.insertText(data_add_option, insertPosition);
      });

      this.dataCorrectSentenceCompletion = this.transformParagraphToCorrects(refCkEditor.getData());
      refCkEditor.editing.view.focus();
      this.dialogSentenceCompletion = false;
    },
    async getRefContentQuestionUniversal() {
      let vm = this;
      if (this.$utils.isValueInvalid(this.contentQuestionInput)) {
        await this.createInstanceRefContentQuestionInput();
      }
      if (vm.hasConfigPhonetic) {
        return vm.refContentPhoneticQuestionUniversal.$refs.refContentQuestionUniversal
      }
      return vm.refContentQuestionUniversal;
    },
    chainCorrectSentenceCompletion(array) {
      let string = "";
      for (let index = 0; index < array.length; index++) {
        string += array[index].trim() + "//";
      }

      return string.substring(0, string.length - 2);
    },
    changeCorrectValueSentenceCompletion(value, index) {
      this.optionSentenceCompletion.splice(index, 1, value);
    },
    deleteCorrectSentenceCompletion(index) {
      if (this.optionSentenceCompletion.length <= 1) {
        this.messError("Phải có tối thiểu 1 đáp án !");
        return;
      }
      this.optionSentenceCompletion.splice(index, 1);
    },

    addObfuscateSentenceCompletion() {
      this.dataObfuscateSentenceCompletion.push({uuid: null, value: null, offset: this.dataObfuscateSentenceCompletion.length + 1});
    },
    deleteObfuscateSentenceCompletion(index) {
      if (this.dataObfuscateSentenceCompletion.length <= 1) {
        this.messError("Phải có tối thiểu 1 từ nhiễu !");
        return;
      }
      this.dataObfuscateSentenceCompletion.splice(index, 1);
    },
    transformParagraphToCorrects(paragraph) {
      const corrects = [];
      const regex = /{{(.*?)}}/g;
      let match;
      let offset = 1;

      while ((match = regex.exec(paragraph)) !== null) {
        const words = match[1].split('//');
        words.forEach(word => {
          corrects.push({ uuid: null, value: word.trim(), offset: offset++ });
        });
      }

      return corrects;
    },
    async getPinyinFromChineseTextSelectedToRefInputEditor() {
      let refComponent = await this.getRefContentQuestionUniversal();
      if (!refComponent) {
        this.messError('Có lỗi ! Hãy báo bên kỹ thuật');
        return;
      }
      let refTitleQuestion = refComponent.$refs.editorCustom;
      let editor = refTitleQuestion.$_instance;

      let textSelected = this.getTextSelectedByEditor(editor);
      if (!textSelected) {
        this.messError('Chưa chọn vùng text để lấy pinyin');
        return;
      }

      let dataPinyin = await this.getDataPinyinByTextSelected(textSelected);
      if (!dataPinyin) return;

      editor.model.change( writer => {
        const range = editor.model.document.selection.getFirstRange();
        editor.model.insertContent(writer.createText(dataPinyin) , range )
      } );

      editor.editing.view.focus();
    },
    async replacePinyinByTextSelectedViaInputOptionRef(inputRef = '', indexOption) {
      if (!inputRef) {
        this.messError('Có lỗi ! Hãy báo bên kỹ thuật');
        return;
      }
      let refOption = this.$refs[inputRef][0].$refs;
      let input = refOption.input;

      const textSelected = input.value.substring(
          input.selectionStart,
          input.selectionEnd,
      );

      if (!textSelected) {
        this.messError('Chưa chọn vùng text để lấy pinyin');
        return;
      }

      const starSto = input.value.substring(0, input.selectionStart);
      const endSto = input.value.substring(input.selectionEnd, input.value.length);

      const dataPinyin = await this.getDataPinyinByTextSelected(textSelected);
      if (!dataPinyin) return;

      this.dataObfuscateSentenceCompletion[indexOption].value =
          starSto +
          " " + dataPinyin + " " +
          endSto
      ;

      setTimeout(() => {
        input.focus();
        input.selectionStart = starSto.length + dataPinyin.length +2;
        input.selectionEnd = starSto.length + dataPinyin.length +2;
      }, 10);

    },
  },

}
</script>

<style scoped>
.label_default {
  font-size: 16px;
}
.container-pinyin-preview {
  height: auto;
  min-height: 40px;
  font-size: 28px;
  line-height: normal;
}
</style>