export default {
  data() {
    return {
      test_type_expand_constant: {
        TEST: 0,
        LESSON: 1,
      },
      product_category_constant: {
        IELTS: 1,
        SUPPLEMENTARY: 2,
        THPTQG: 3,
        TOEIC: 4,
        HSK: 5,
        PREPTALK_ENGLISH: 6,
      },
      skill_constant: {
        LISTENING: 1,
        READING: 2,
        SPEAKING: 3,
        WRITING: 4,
        VOCABULARY: 11,
        PRONUNCIATION: 12,
        GRAMMAR: 13,
        WRITING_ALTERNATIVE: 31,
        SPEAKING_ALTERNATIVE: 32,
      },
      exercise_type_constant: {
        SHORT_ANSWER: 1,
        SINGLE_CHOICE: 2,
        TRUE_FALSE_NOT_GIVEN: 21,
        YES_NO_NOT_GIVEN: 22,
        SINGLE_SELECT: 3,
        MULTIPLE_CHOICE: 4,
        LONG_ANSWER: 5,
        FILE_UPLOAD: 6,
        MATCHING: 7,
        ANSWER_IN_ANY_ORDER: 8,
        RECORDING: 9,
        INDIVIDUAL_SOUNDS: 10,
        WORD_STRESSES: 11,
        INDIVIDUAL_SOUNDS_AND_WORD_STRESSES: 12,
        SENTENCE_STRESSES: 13,
        INDIVIDUAL_SOUNDS_AND_SENTENCE_STRESSES: 14,
        WRITING_NO_MARKING: 15,
        SINGLE_CHOICE_EDITOR: 16,
        SINGLE_CHOICE_IN_PARAGRAPH: 17,
        WRITING_AUTO_MARKING: 18,
        SHADOWING: 19,
        VIRTUAL_WRITING_ROOM: 20,
        MATCHING_PAIR: 23,
        HSK_SENTENCE_SPEECH_ASSESSMENT: 24,
        HSK_PARAGRAPH_SPEECH_ASSESSMENT: 25,
        VIRTUAL_WRITING_ROOM_WT1_IELTS: 26,
        UNIVERSAL: 27,
        SENTENCE_COMPLETION: 28,
        SINGLE_CHOICE_ELEMENT: 29,
        MATCHING_CARD: 30,
        SENTENCE_SCRAMBLE: 31,
        MOCK_CONVERSATION: 32,
        VIRTUAL_CONVERSATION: 33,
      },
      section_offset_constant: {
        OFFSET_1: 1,
        OFFSET_2: 2,
        OFFSET_3: 3,
        OFFSET_4: 4,
        OFFSET_5: 5,
      },
      section_config_module_constant: {
        VSR_MODULE: 1,
        VWR_MODULE: 2,
        TEST_PRACTICE_MODULE: 3,
        PREP_TALK_MODULE: 4,
      },
      element_type_constant: {
        TEXT: 1,
        IMAGE: 2,
        FILE: 3,
        LINK: 4,
        VIDEO: 5,
        POST: 6,
        AUDIO: 7,
        PDF: 8,
        ALBUM: 9,
        YOUTUBE: 10,
        DIRECTION: 11,
        FLASHCARD: 12,
      },
      question_content_type_constant: {
         TEXT: 1,
         IMAGE: 2,
         AUDIO: 3,
         TEXT_AND_IMAGE: 4,
         TEXT_AND_AUDIO: 5,
      },
      question_layout_type_constant: {
        GALLERY: 'gallery',
        LIST: 'list',
      },

    }
  },
  computed: {
    exercise_types: function () {
      return [
        {label: "None", value: null},
        {label: "Matching Pair", value: this.exercise_type_constant.MATCHING_PAIR},
        {label: "HSK Sentence Speech Assessment", value: this.exercise_type_constant.HSK_SENTENCE_SPEECH_ASSESSMENT},
        {label: "Short Answer in Paragraph", value: this.exercise_type_constant.SHORT_ANSWER},
        {label: "True/False/Not Given", value: this.exercise_type_constant.TRUE_FALSE_NOT_GIVEN},
        {label: "Yes/No/Not Given", value: this.exercise_type_constant.YES_NO_NOT_GIVEN},
        {label: "Single Choice", value: this.exercise_type_constant.SINGLE_CHOICE},
        {label: "Single Choice Editor", value: this.exercise_type_constant.SINGLE_CHOICE_EDITOR},
        {label: "Single Select", value: this.exercise_type_constant.SINGLE_SELECT},
        {label: "Multiple Choice", value: this.exercise_type_constant.MULTIPLE_CHOICE},
        {label: "Writing", value: this.exercise_type_constant.LONG_ANSWER},
        {label: "Writing No Marking", value: this.exercise_type_constant.WRITING_NO_MARKING},
        {label: "Recording", value: this.exercise_type_constant.RECORDING},
        {label: "File Upload", value: this.exercise_type_constant.FILE_UPLOAD},
        {label: "Matching", value: this.exercise_type_constant.MATCHING},
        {label: "List Selection", value: this.exercise_type_constant.ANSWER_IN_ANY_ORDER},
        {label: "Sentence Stressed", value: this.exercise_type_constant.SENTENCE_STRESSES},
        {label: "Auto Marking Individual Sounds", value: this.exercise_type_constant.INDIVIDUAL_SOUNDS},
        {label: "Auto Marking Word Stresses", value: this.exercise_type_constant.WORD_STRESSES},
        {label: "Auto Marking Individual Sounds And Word Stresses", value: this.exercise_type_constant.INDIVIDUAL_SOUNDS_AND_WORD_STRESSES},
        {label: "Individual Sounds & Sentence Stress", value: this.exercise_type_constant.INDIVIDUAL_SOUNDS_AND_SENTENCE_STRESSES},
        {label: "Single Choice In Paragraph", value: this.exercise_type_constant.SINGLE_CHOICE_IN_PARAGRAPH},
        {label: "Writing Auto Marking", value: this.exercise_type_constant.WRITING_AUTO_MARKING},
        {label: "Shadowing", value: this.exercise_type_constant.SHADOWING},
        {label: "Virtual Writing Room", value: this.exercise_type_constant.VIRTUAL_WRITING_ROOM},
        {label: "Virtual Writing Room WT1 IELTS", value: this.exercise_type_constant.VIRTUAL_WRITING_ROOM_WT1_IELTS},
        {label: "Universal", value: this.exercise_type_constant.UNIVERSAL},
        {label: "Sentence Completion", value: this.exercise_type_constant.SENTENCE_COMPLETION},
        {label: "Single Choice With Element", value: this.exercise_type_constant.SINGLE_CHOICE_ELEMENT},
        {label: "Matching Card", value: this.exercise_type_constant.MATCHING_CARD},
        {label: "Sentence Scramble", value: this.exercise_type_constant.SENTENCE_SCRAMBLE},
        {label: "Dialogue Shadowing", value: this.exercise_type_constant.MOCK_CONVERSATION},
        {label: "Virtual Conversation", value: this.exercise_type_constant.VIRTUAL_CONVERSATION},
      ]
    },
    question_content_types: function () {
      return [
        {label: "Text", value: this.question_content_type_constant.TEXT},
        {label: "Ảnh", value: this.question_content_type_constant.IMAGE},
        {label: "Audio", value: this.question_content_type_constant.AUDIO},
      ]
    },
    question_layout_types: function () {
      return [
        {label: "Gallery", value: this.question_layout_type_constant.GALLERY},
        {label: "List", value: this.question_layout_type_constant.LIST},
      ]
    },
  },
  methods: {
    listLabelProductCategory() {
      return [
        { value: this.product_category_constant.IELTS, label: "IELTS" },
        { value: this.product_category_constant.SUPPLEMENTARY, label: "Supplementary" },
        { value: this.product_category_constant.THPTQG, label: "THPTQG" },
        { value: this.product_category_constant.TOEIC, label: "TOEIC" },
        { value: this.product_category_constant.HSK, label: "HSK" },
        { value: this.product_category_constant.PREPTALK_ENGLISH, label: "PREPTALK_ENGLISH" },
      ];
    },
    listLabelSkill() {
      return [
        { value: this.skill_constant.LISTENING, label: "Nghe" },
        { value: this.skill_constant.READING, label: "Đọc" },
        { value: this.skill_constant.SPEAKING, label: "Nói (Giáo viên chấm)" },
        { value: this.skill_constant.WRITING, label: "Viết (Giáo viên chấm)" },
        { value: this.skill_constant.VOCABULARY, label: "Từ vựng" },
        { value: this.skill_constant.PRONUNCIATION, label: "Phát âm" },
        { value: this.skill_constant.GRAMMAR, label: "Ngữ pháp" },
        { value: this.skill_constant.WRITING_ALTERNATIVE, label: "Viết (Tự động chấm)" },
        { value: this.skill_constant.SPEAKING_ALTERNATIVE, label: "Nói (Tự động chấm)" },
      ];
    },
    getLabelTypeByValueExercise(type) {
      let result = '';
      for (let i = 0; i < this.exercise_types.length; i++) {
        if (this.exercise_types[i].value === type) {
          result = this.exercise_types[i].label;
          break;
        }
      }
      return result;
    },
    getValueTypeByLabelExercise(label) {
      let result = '';
      for (let i = 0; i < this.exercise_types.length; i++) {
        if (this.exercise_types[i].label === label) {
          result = this.exercise_types[i].value;
          break;
        }
      }
      return result;
    },
    getLabelByTypeElement(type) {
      let type_txt;
      switch (type) {
        case this.element_type_constant.TEXT:
          type_txt = "Post";
          break;
        case this.element_type_constant.IMAGE:
          type_txt = "Image";
          break;
        case this.element_type_constant.FILE:
          type_txt = "File";
          break;
        case this.element_type_constant.LINK:
          // type_txt = 'Embed Youtube'
          type_txt = "Link";
          break;
        case this.element_type_constant.VIDEO:
          type_txt = "Video";
          break;
        case this.element_type_constant.POST:
          type_txt = "Post";
          break;
        case this.element_type_constant.AUDIO:
          type_txt = "Audio";
          break;
        case this.element_type_constant.PDF:
          type_txt = "Pdf";
          break;
        case this.element_type_constant.ALBUM:
          type_txt = "Album";
          break;
        case this.element_type_constant.YOUTUBE:
          type_txt = "Embed_yt";
          break;
        case this.element_type_constant.FLASHCARD:
          type_txt = "Flashcard";
          break;
        default:
          type_txt = null;
          break;
      }
      return type_txt;
    },
    isElementTypeForUploadFile(type) {
      return [
        this.element_type_constant.IMAGE,
        this.element_type_constant.FILE,
        this.element_type_constant.AUDIO,
        this.element_type_constant.PDF,
        this.element_type_constant.ALBUM,
      ].includes(type);
    },
    checkIsElementTypeForUploadFileByTypeTxt(type_txt) {
      let type = this.getTypeByLabelElement(type_txt);
      if (type === null) {
        return false;
      }
      return this.isElementTypeForUploadFile(type);
    },
    getTypeByLabelElement(label) {
      let type;
      switch (label) {
        case "Post":
          type = this.element_type_constant.TEXT;
          break;
        case "Image":
          type = this.element_type_constant.IMAGE;
          break;
        case "File":
          type = this.element_type_constant.FILE;
          break;
        case "Link":
          type = this.element_type_constant.LINK;
          break;
        case "Video":
          type = this.element_type_constant.VIDEO;
          break;
        case "Audio":
          type = this.element_type_constant.AUDIO;
          break;
        case "Pdf":
          type = this.element_type_constant.PDF;
          break;
        case "Album":
          type = this.element_type_constant.ALBUM;
          break;
        case "Embed_yt":
          type = this.element_type_constant.YOUTUBE;
          break;
        default:
          type = null;
          break;
      }
      return type;
    },
    listExerciseTypeInUniversalType() {
      return [
        this.exercise_type_constant.SINGLE_CHOICE,
        this.exercise_type_constant.MULTIPLE_CHOICE,
        this.exercise_type_constant.SENTENCE_COMPLETION,
        this.exercise_type_constant.SINGLE_CHOICE_ELEMENT,
        this.exercise_type_constant.MATCHING_CARD,
        this.exercise_type_constant.SENTENCE_SCRAMBLE,
      ];
    },
    listExerciseTypeNotSupportForQuestionNormal() {
      return [
        this.exercise_type_constant.SENTENCE_COMPLETION,
        this.exercise_type_constant.SINGLE_CHOICE_ELEMENT,
        this.exercise_type_constant.MATCHING_CARD,
        this.exercise_type_constant.SENTENCE_SCRAMBLE,
      ];
    },
    listExerciseTypeCanConfigPinyin(){
      return [
        this.exercise_type_constant.SINGLE_CHOICE,
        this.exercise_type_constant.SINGLE_CHOICE_EDITOR,
        this.exercise_type_constant.MULTIPLE_CHOICE,
        this.exercise_type_constant.HSK_SENTENCE_SPEECH_ASSESSMENT,
        this.exercise_type_constant.SHORT_ANSWER,
        this.exercise_type_constant.MATCHING,
        this.exercise_type_constant.MATCHING_PAIR,
        this.exercise_type_constant.SINGLE_SELECT,
        this.exercise_type_constant.ANSWER_IN_ANY_ORDER,
        this.exercise_type_constant.UNIVERSAL,
        this.exercise_type_constant.RECORDING,
      ];
    },
  },
}