<template>
  <div style="width: 100%" class="d-flex">
    <div style="width: 66%">
      <label class="label_default">Nhập mô tả câu hỏi</label>
      <Editor ref="refContentQuestionUniversal" placeholder="Nhập mô tả câu hỏi" v-model="contentQuestionInput"></Editor>
    </div>
    <div class="border rounded-lg mt-6 ml-3" style="width: 34%">
      <p
          style="background-color: #fafafa"
          class="text-h6 py-5 text-center mb-0">Preview</p>
      <div
          class="p-2 overflow-auto container-pinyin-preview"
          style="height: 200px"
          v-html="decodeHTMLForChineseCharacter(contentQuestionInput)"></div>
    </div>
  </div>
</template>

<script>
import phoneticSupport from "../Support/Question/phoneticSupport";

export default {
  name: "ContentPhoneticForSentenceCompletionQuestionInput",
  mixins: [
    phoneticSupport,
  ],
  components: {
    Editor: () => import("@/components/ckeditor5/Editor.vue"),
  },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      contentQuestionInput: this.value,
    };
  },
  watch: {
    value: {
      handler(val) {
        this.contentQuestionInput = val;
      },
      immediate: true,
    },
    contentQuestionInput: {
      handler(val) {
        this.$emit("input", val);
      },
      immediate: true,
    },
  },

}
</script>

<style scoped>
.label_default {
  font-size: 16px;
}
.container-pinyin-preview {
  height: auto;
  min-height: 40px;
  font-size: 28px;
  line-height: normal;
}
</style>