<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div class="card card-custom">
      <template v-if="courseId != null">
        <v-row>
          <v-col cols="5">
            <router-link
              :to="{ name: 'CourseContent', params: { id: courseId } }"
            >
              <v-btn
                color="#1479F4"
                style="color: white; font-weight: bold"
                class="mb-5 ml-5"
                x-large
              >
                <i class="fa fa-chevron-left" style="color: white"></i
                >&nbsp;Quay về khóa học
              </v-btn>
            </router-link>
          </v-col>
        </v-row>
      </template>
      <div class="card-body p-0">
        <!--begin: Wizard-->
        <div
          class="wizard wizard-1"
          id="kt_wizard_v1"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <!--begin: Wizard Nav-->
          <div class="wizard-nav border-bottom">
            <div class="wizard-steps p-8 p-lg-10">
              <div
                class="wizard-step"
                data-wizard-type="step"
                data-wizard-state="current"
              >
                <div class="wizard-label" style="color: #1479f4">
                  <i class="wizard-icon flaticon-information"></i>
                  <h3 class="wizard-title" style="font-weight: bold !important">
                    1. Nhập thông tin bài học / bài kiểm tra
                  </h3>
                </div>
                <i class="wizard-arrow flaticon2-next"></i>
              </div>
              <div class="wizard-step" data-wizard-type="step">
                <div class="wizard-label">
                  <i class="wizard-icon flaticon-list-1"></i>
                  <h3 class="wizard-title" style="font-weight: bold !important">
                    2. Nhập nội dung chi tiết
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <!--end: Wizard Nav-->

          <!--begin: Wizard Body-->
          <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
            <div class="col-xl-12 col-xxl-12">
              <!--begin: Wizard Form-->
              <form class="form" id="kt_form">
                <!--begin: Wizard Step 1-->
                <div
                  class="pb-5"
                  data-wizard-type="step-content"
                  data-wizard-state="current"
                >
                  <InformationForm :courseId="courseId"></InformationForm>
                </div>
                <!--end: Wizard Step 1-->

                <!--begin: Wizard Step 2-->
                <div class="pb-5" data-wizard-type="step-content">
                  <!-- <ContentForm :data_skills="skills"></ContentForm> -->
                  <h4 class="mb-10 font-weight-bold text-dark" id="topSkill">
                    2. Nhập nội dung chi tiết
                  </h4>
                  <v-btn
                    outlined
                    color="#1479F4"
                    class="mb-2"
                    @click="openDialogSkill()"
                  >
                    <v-icon style="color: #1479f4">mdi-plus</v-icon>
                    <p
                      style="color: #1479f4; font-weight: bold"
                      class="ml-2 mt-4"
                    >
                      Thêm kỹ năng (Skill)
                    </p>
                  </v-btn>
                  <v-btn
                      outlined
                      color="#00897B"
                      class="mb-2 ml-2"
                      @click="openDialogSortSkill()"
                  >
                    <v-icon style="color: #00897B">mdi-sort</v-icon>
                    <p
                        style="color: #00897B; font-weight: bold"
                        class="ml-2 mt-4"
                    >
                      Sắp xếp kĩ năng
                    </p>
                  </v-btn>
                  <v-card v-if="skills.length">
                    <v-tabs
                      v-model="tabSkill"
                      class="tab-skill"
                      background-color="#1479F4"
                      dark
                    >
                      <v-tab
                        v-for="(skill, index) in skills"
                        :id="'skill' + index"
                        :key="skill.id"
                      >
                        <span class="font-weight-bold">
                          {{ skill.skill_type }}</span
                        >
                        <v-icon right @click="editSkill(index)"
                          >mdi-file-document-edit-outline</v-icon
                        >
                        <v-icon
                          right
                          @click="
                            deleteSkill(index, skill.id_skill_test_mapping)
                          "
                          >mdi-close</v-icon
                        >
                      </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tabSkill">
                      <v-tab-item
                        v-for="(skill, index) in skills"
                        :key="skill.id"
                      >
                        <v-card flat>
                          <v-card-text id="containerExer">
                            <v-tabs
                              vertical
                              v-model="skill.currentTabSection"
                              color="#1479F4"
                            >
                              <template v-for="(section, i) in skill.sections">
                                <v-tab
                                  :key="i"
                                  class="tab-section"
                                >
                                  <v-icon left>mdi-format-align-justify</v-icon>
                                  {{ section.section_title }}
                                </v-tab>
                              </template>
                              <div
                                class="txtAddSection"
                                @click="openDialogSection(index, skill.id)"
                              >
                                <v-icon color="#1479F4">mdi-plus-circle</v-icon>
                                Thêm Section
                              </div>
                              <template v-for="(section, i) in skill.sections">
                                <v-tab-item
                                  :key="i"
                                >
                                  <v-card flat class="ml-4">
                                    <!-- action section -->
                                    <SectionAction
                                      :iSkill="convertToNumber(index)"
                                      :iSection="convertToNumber(i)"
                                      :section="section"
                                      :skill="skill"
                                      :scrollBtnSection="scrollBtnSection"
                                    ></SectionAction>
                                    <!-- end action section -->

                                    <!--action element-->
                                    <div
                                      class="mt-4"
                                      v-if="
                                        section.element_type != null &&
                                        section.element_data != null
                                      "
                                    >
                                      <v-btn
                                        class="ml-2 white--text"
                                        color="#F57C00"
                                        small
                                        v-if="section.is_element_pinned"
                                      >
                                        <v-icon left>mdi-pin</v-icon> Element
                                        được pin to top
                                      </v-btn>
                                      <v-btn
                                        color="cyan"
                                        v-if="section.element_type === 'Video'"
                                        class="ma-2 font-weight-bold"
                                        outlined
                                        @click="
                                          openDialogTimeStamp(
                                            index,
                                            i,
                                            section.id
                                          )
                                        "
                                      >
                                        <v-icon left>mdi-chart-timeline</v-icon>
                                        Tạo timestamp cho video
                                      </v-btn>
                                    </div>
                                    <!--end action element-->
                                    <div
                                      class="mt-4"
                                      v-if="
                                        section.element_type != null &&
                                        section.element_data != null
                                      "
                                    >
                                      <ElementType
                                        :element_type="section.element_type"
                                        :element_data="section.element_data"
                                      ></ElementType>
                                    </div>
                                    <div
                                      class="mt-4 ml-8"
                                      v-if="
                                        section.sub_element_type != null &&
                                        section.sub_element_data != null
                                      "
                                    >
                                      <ElementType
                                        style="width: 70%"
                                        :element_type="section.sub_element_type"
                                        :element_data="section.sub_element_data"
                                      ></ElementType>
                                    </div>
                                    <div class="mt-4" id="containerExer2">
                                      <v-btn
                                        :outlined="!scrollBtnExer"
                                        :class="{ 'scroll-btn-exer': scrollBtnExer, }"
                                        @click=" openDialogExercise( index, i, skill.id, section )"
                                        class="ma-2 font-weight-bold"
                                        color="indigo"
                                      >
                                        <v-icon>mdi-note-plus</v-icon> Thêm bài
                                        tập (Exercise)
                                      </v-btn>
                                      <template
                                        v-for="( exercise, iExer ) in section.exercises"
                                      >
                                        <div class="ml-12 mt-4" :key="iExer">
                                          <!-- action exercise -->
                                          <ExerciseAction
                                            :iSkill="convertToNumber(index)"
                                            :iSection="convertToNumber(i)"
                                            :iExercise="convertToNumber(iExer)"
                                            :section="section"
                                            :skill="skill"
                                            :exercise="exercise"
                                          ></ExerciseAction>
                                          <!-- end action exercise -->
                                          <p
                                            v-if="exercise.audio_timer"
                                            class="text-overline"
                                          >
                                            Audio Time:
                                            <span style="color: rgb(47, 187, 52)">{{ exercise.audio_timer | convertSecondToHMS }}</span>
                                          </p>
                                          <p
                                            class="text-sm-subtitle-1 font-italic"
                                            style="color: #6495ed"
                                          >
                                            Loại bài tập:
                                            {{ getLabelTypeByValueExercise(exercise.exercise_type) }}
                                          </p>
                                          <!-- <pre class="text-description ml-2 text-h6">{{exercise.exercise_description}}</pre> -->
                                          <div
                                            style="max-width: 60%"
                                            v-html=" exercise.exercise_description"
                                          ></div>
                                          <div
                                            style=""
                                            v-if=" exercise.element_type != null && exercise.element_data != null"
                                          >
                                            <ElementType
                                              :element_type=" exercise.element_type"
                                              :element_data=" exercise.element_data"
                                            ></ElementType>
                                          </div>
                                          <div
                                            v-if=" exercise.sub_element_type != null && exercise.sub_element_data != null"
                                          >
                                            <ElementType
                                              style="width: 70%"
                                              :element_type=" exercise.sub_element_type"
                                              :element_data=" exercise.sub_element_data"
                                            ></ElementType>
                                          </div>
                                          <div>
                                            <template v-if=" exercise.exercise_type === exercise_type_constant.MATCHING">
                                              <MatchingType
                                                :section="section"
                                                :exercise="exercise"
                                                :type_expand="type_expand"
                                              ></MatchingType>
                                            </template>
                                            <template v-else-if=" exercise.exercise_type === exercise_type_constant.ANSWER_IN_ANY_ORDER">
                                              <ListSectionType
                                                :section="section"
                                                :exercise="exercise"
                                                :type_expand="type_expand"
                                              ></ListSectionType>
                                            </template>
                                            <template v-else-if=" exercise.exercise_type === exercise_type_constant.SINGLE_CHOICE_IN_PARAGRAPH">
                                              <SingleChoiceParagraphType
                                                :section="section"
                                                :exercise="exercise"
                                                :type_expand="type_expand"
                                              ></SingleChoiceParagraphType>
                                            </template>
                                            <template v-else-if=" exercise.exercise_type === exercise_type_constant.SHORT_ANSWER">
                                              <div
                                                @click=" editExercise( index, i, iExer, skill.id, section.id, exercise.id )"
                                              >
                                                <p
                                                  class="mt-0 mb-0 font-italic text-sm-subtitle-1 text-warning"
                                                >
                                                  {{ exercise.total_curly_braces }} câu hỏi đã được nhập trong bài tập
                                                </p>
                                                <v-textarea
                                                  label=""
                                                  readonly solo auto-grow
                                                  v-model.trim="exercise.body"
                                                  rows="8"
                                                ></v-textarea>
                                              </div>
                                              <!-- <div v-if="exercise.explanation != null">
                                                <template v-if="exercise.explanation.length">
                                                  <p class="mt-0 mb-0 text-sm-subtitle-1">Giải thích đáp án</p>
                                                  <Editor :value="exercise.explanation"></Editor>
                                                </template>
                                              </div> -->
                                            </template>
                                            <template v-else-if=" exercise.exercise_type === exercise_type_constant.MATCHING_PAIR">
                                              <MatchingPairType
                                                  :section="section"
                                                  :exercise="exercise"
                                                  :type_expand="type_expand"
                                              />
                                            </template>
                                            <template v-else-if=" exercise.exercise_type === exercise_type_constant.MOCK_CONVERSATION">
                                              <MockConversationType
                                                :iSkill="convertToNumber(index)"
                                                :iSection="convertToNumber(i)"
                                                :iExercise="convertToNumber(iExer)"
                                                :section="section"
                                                :exercise="exercise"
                                                :type_expand="convertToNumber(type_expand)"
                                              ></MockConversationType>
                                            </template>
                                            <template v-else-if=" exercise.exercise_type === exercise_type_constant.VIRTUAL_CONVERSATION">
                                            </template>
                                            <template v-else>
                                              <template
                                                v-for="( question, iQues ) in exercise.questions">
                                                <div
                                                  v-if=" exercise.questions.length"
                                                  class="ml-12 mt-4"
                                                  :key="question.id"
                                                >
                                                  <!-- action question -->
                                                  <QuestionAction
                                                    :iSkill="convertToNumber(index)"
                                                    :iSection="convertToNumber(i)"
                                                    :iExercise="convertToNumber(iExer)"
                                                    :iQuestion="convertToNumber(iQues)"
                                                    :section="section"
                                                    :skill="skill"
                                                    :exercise="exercise"
                                                    :question="question"
                                                  ></QuestionAction>
                                                  <!-- end action question -->

                                                  <div
                                                    style="width: 50%"
                                                    v-if="
                                                      question.element_type !=
                                                        null &&
                                                      question.element_data !=
                                                        null
                                                    "
                                                  >
                                                    <ElementType
                                                      :element_type="
                                                        question.element_type
                                                      "
                                                      :element_data="
                                                        question.element_data
                                                      "
                                                    ></ElementType>
                                                  </div>

                                                  <!-- <p class="text-description">{{question.description}}</p> -->
                                                  <!-- hiện thị question theo type -->
                                                  <QuestionType
                                                    :question="question"
                                                    :exercise="exercise"
                                                    :indexComponent="iQues"
                                                  ></QuestionType>
                                                  <!-- kết thúc hiện thị question theo type -->
                                                </div>
                                              </template>
                                              <v-btn
                                                @click=" openDialogQuestion( index, i, iExer, exercise.id )"
                                                class="ma-2 font-weight-bold"
                                                style="color: saddlebrown"
                                                outlined
                                              >
                                                <v-icon dark
                                                  >mdi-pencil-plus-outline</v-icon
                                                >Thêm câu hỏi (Question)
                                              </v-btn>
                                              <template
                                                v-if=" exercise.is_use_suggestion_order === 1"
                                              >
                                                <v-btn
                                                  @click=" openDialogSuggestOrderQues( index, i, iExer )"
                                                  class="ma-2 font-weight-bold"
                                                  style="color: #f57f17"
                                                  outlined
                                                >
                                                  <v-icon dark
                                                    >mdi-pencil-plus-outline</v-icon
                                                  >Tráo thứ tự hiện thị
                                                </v-btn>
                                              </template>
                                            </template>
                                          </div>
                                        </div>
                                      </template>
                                    </div>
                                  </v-card>
                                </v-tab-item>
                              </template>
                            </v-tabs>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-card>
                </div>
                <!--end: Wizard Step 2-->

                <!--begin: Wizard Actions -->
                <div class="d-flex justify-content-between border-top pt-10">
                  <div class="mr-2">
                    <button
                      class="btn font-weight-bold text-uppercase px-9 py-4 btn-action-builder"
                      data-wizard-type="action-prev"
                    >
                      <i class="fa fa-chevron-left" style="color: white"></i>
                      Bước nhập thông tin
                    </button>
                  </div>

                  <div>
                    <button
                      v-if="test_id != null && currentStep === 2"
                      @click.prevent="btnDraftTest"
                      style="color: white"
                      class="btn font-weight-bold btn-xs text-uppercase px-9 py-4 btn-warning mr-4"
                    >
                      <i class="fa fa-save" style="color: white"></i>Lưu nháp
                    </button>
                    <button
                      v-if="test_id != null && currentStep === 2"
                      @click.prevent="dialogPreview = true"
                      class="btn font-weight-bold btn-xs text-uppercase px-9 py-4 btn-info mr-4"
                    >
                      <i class="fa fa-eye" style="color: white"></i>Xem nội dung
                    </button>
                    <button
                      @click.prevent="submit"
                      class="btn font-weight-bold text-uppercase px-9 py-4 btn-primary"
                      data-wizard-type="action-submit"
                    >
                      <i class="fa fa-check" style="color: white"></i> Tạo bài
                    </button>
                    <button
                      class="btn font-weight-bold text-uppercase px-9 py-4 btn-action-builder"
                      data-wizard-type="action-next"
                    >
                      Nhập nội dung chi tiết
                      <i class="fa fa-chevron-right" style="color: white"></i>
                    </button>
                  </div>
                </div>
                <!--end: Wizard Actions -->
              </form>
              <!--end: Wizard Form-->
            </div>
          </div>
          <!--end: Wizard Body-->
        </div>
      </div>
      <!--end: Wizard-->

      <!-- modal -->
      <ElementModal></ElementModal>
      <SkillModal></SkillModal>
      <SectionModal></SectionModal>
      <ExerciseModal></ExerciseModal>
      <QuestionModal></QuestionModal>
      <ShortAnswerModal></ShortAnswerModal>
      <VideoModal></VideoModal>
      <AudioTimeModal></AudioTimeModal>
      <PreviewLessTest></PreviewLessTest>
      <SortExerciseModal></SortExerciseModal>
      <SortSectionModal></SortSectionModal>
      <SubElementModal></SubElementModal>
      <AddOptionSingleChoiceParagraphModal></AddOptionSingleChoiceParagraphModal>
      <TimestampVideoModal></TimestampVideoModal>
      <SuggestOrderQuestionModal></SuggestOrderQuestionModal>
      <QuestionVWRModal></QuestionVWRModal>
      <SortQuestionModal></SortQuestionModal>
      <SortSkillModal/>
      <AddPhoneticToTitleQuestionModal />
      <QuestionVWRIeltsTask1Modal />
      <QuestionUniversalModal />
      <!-- end modal -->

      <template v-if="currentStep === 2 && !hideMiniMap">
        <div class="menu-test-builder" v-if="skills[tabSkill]">
          <template v-if="skills[tabSkill].sections != undefined">
            <template
              v-if=" skills[tabSkill].sections[skills[tabSkill].currentTabSection]"
            >
              <template
                v-if=" skills[tabSkill].sections[skills[tabSkill].currentTabSection] .exercises != undefined"
              >
                <v-card class="mx-auto" max-width="180" style="padding: 15px">
                  <div class="d-flex">
                    <v-icon style="cursor: pointer" @click="hideMiniMap = true"
                      >mdi-chevron-triple-right</v-icon
                    >
                    <h3
                      v-scroll-to="'#topSkill'"
                      style="
                        text-align: center;
                        cursor: pointer;
                        margin-left: 4vh;
                      "
                    >
                      Top
                    </h3>
                  </div>
                  <template
                    v-for="(exercise, iExer) in skills[tabSkill].sections[
                      skills[tabSkill].currentTabSection
                    ].exercises"
                  >
                    <div :key="iExer">
                      <h4
                        class="text-truncate text-scroll"
                        v-scroll-to=" '#exercise' + tabSkill + skills[tabSkill].currentTabSection + iExer"
                      >
                        {{ iExer + 1 }}. {{ exercise.exercise_title }}
                      </h4>
                      <template
                          v-if="
                            exercise.exercise_type !== exercise_type_constant.SHORT_ANSWER &&
                            exercise.exercise_type !== exercise_type_constant.MATCHING &&
                            exercise.exercise_type !== exercise_type_constant.ANSWER_IN_ANY_ORDER
                          ">
                        <template
                          v-for="(question, iQues) in exercise.questions"
                        >
                          <div
                            :key="iQues"
                            class="pl-4 text-truncate text-scroll d-flex"
                            v-scroll-to=" '#question' + tabSkill + skills[tabSkill].currentTabSection + iExer + iQues"
                          >
                            <p v-if="type_expand == 0" class="mb-0">
                              {{ skills[tabSkill].sections[skills[tabSkill].currentTabSection].offset_question + exercise.offset_question + iQues + 1 }}.
                            </p>
                            <p v-if="type_expand == 1" class="mb-0">
                              {{ iQues + 1 }}.
                            </p>
                            <div class="ml-1" v-html="question.title"></div>
                          </div>
                        </template>
                      </template>
                    </div>
                  </template>
                </v-card>
              </template>
            </template>
          </template>
        </div>
      </template>
      <template v-if="currentStep === 2 && hideMiniMap">
        <div class="menu-test-builder">
          <v-card class="mx-auto" max-width="180" style="padding: 15px">
            <div class="d-flex" @click="hideMiniMap = false">
              <v-icon style="cursor: pointer">mdi-chevron-triple-left</v-icon>
              <h3 class="mt-1 ml-1" style="text-align: center; cursor: pointer">
                Mini map
              </h3>
            </div>
          </v-card>
        </div>
      </template>
    </div>
  </v-app>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-1.scss";
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import Swal from "sweetalert2";
import { mapState, mapGetters } from "vuex";
import SingleChoiceParagraphType from "@/view/pages/tests/Form/Displays/SingleChoiceParagraphType";
import SortQuestionModal from "../Modal/SortQuestionModal";
import SortSkillModal from "../Modal/SortSkillModal";
import MatchingPairType from "./Displays/MatchingPairType";
import AddPhoneticToTitleQuestionModal from "../Modal/AddPhoneticToTitleQuestionModal";
import QuestionVWRIeltsTask1Modal from "../Modal/QuestionVWRIeltsTask1Modal";
import transformSkillTestData from "../mixins/Support/transformSkillTestData";
import constantTestBuilder from "../mixins/Support/constantTestBuilder";
import QuestionUniversalModal from "../Modal/QuestionUniversalModal";

export default {
  name: "TestBuilderForm",
  components: {
    QuestionUniversalModal,
    QuestionVWRIeltsTask1Modal,
    AddPhoneticToTitleQuestionModal,
    SortSkillModal,
    MatchingPairType,
    SortQuestionModal,
    SingleChoiceParagraphType,
    Loading: () => import("vue-loading-overlay"),
    InformationForm: () => import("./InformationForm"),
    SkillModal: () => import("../Modal/SkillModal"),
    SectionModal: () => import("../Modal/SectionModal"),
    ElementModal: () => import("../Modal/ElementModal"),
    VideoModal: () => import("../Modal/VideoModal"),
    ExerciseModal: () => import("../Modal/ExerciseModal"),
    ShortAnswerModal: () => import("../Modal/ShortAnswerModal"),
    QuestionModal: () => import("../Modal/QuestionModal"),
    AudioTimeModal: () => import("../Modal/AudioTimeModal"),
    PreviewLessTest: () => import("../Modal/PreviewLessTest"),
    ElementType: () => import("./Displays/ElementType"),
    QuestionType: () => import("./Displays/QuestionType"),
    MatchingType: () => import("./Displays/MatchingType"),
    ListSectionType: () => import("./Displays/ListSectionType"),
    SortExerciseModal: () => import("../Modal/SortExerciseModal"),
    SortSectionModal: () => import("../Modal/SortSectionModal"),
    SubElementModal: () => import("../Modal/SubElementModal"),
    SectionAction: () => import("./Actions/SectionAction"),
    ExerciseAction: () => import("./Actions/ExerciseAction"),
    QuestionAction: () => import("./Actions/QuestionAction"),
    AddOptionSingleChoiceParagraphModal: () =>
      import("../Modal/AddOptionSingleChoiceParagraphModal"),
    TimestampVideoModal: () => import("../Modal/TimestampVideoModal"),
    SuggestOrderQuestionModal: () =>
      import("../Modal/SuggestOrderQuestionModal"),
    QuestionVWRModal: () => import("../Modal/QuestionVWRModal"),
    MockConversationType: () => import("./Displays/MockConversationType"),
  },
  mixins: [
      transformSkillTestData,
      constantTestBuilder,
  ],
  props: {
    courseId: {
      type: Number,
      default: null,
    },
    type_form_test: {},
    is_free: {},
    offset: null,
    testId: {
      default: null,
    },
    dataTest: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      currentStep: 1,
      scrollBtnSection: false,
      scrollBtnExer: false,
      hideMiniMap: false,
    };
  },
  computed: {
    ...mapState({
      test_id: (state) => state.informationTestBuilderStore.test_id,
      title_test: (state) => state.informationTestBuilderStore.title_test,
      sub_title: (state) => state.informationTestBuilderStore.sub_title,
      description_test: (state) =>
        state.informationTestBuilderStore.description_test,
      time_restriction_test: (state) =>
        state.informationTestBuilderStore.time_restriction_test,
      duration_test: (state) => state.informationTestBuilderStore.duration_test,
      grading_scale: (state) => state.informationTestBuilderStore.grading_scale,
      type_expand: (state) => state.informationTestBuilderStore.type_expand,
      paid_test: (state) => state.informationTestBuilderStore.paid_test,
      tag_selected: (state) => state.informationTestBuilderStore.tag_selected,
      predict: (state) => state.informationTestBuilderStore.predict,
      expired_predict_date: (state) => state.informationTestBuilderStore.expired_predict_date,
      tabSkill: (state) => state.contentTestBuilderStore.tabSkill,
      tabSection: (state) => state.contentTestBuilderStore.tabSection,
    }),
    ...mapGetters("informationTestBuilderStore", [
      "cover_test_data",
      "cover_test_file",
      "is_upload_cover_test",
      "data_option_skills",
      "pdf_test_data",
      "pdf_test_file",
      "is_upload_pdf_test",
    ]),
    ...mapGetters("contentTestBuilderStore", ["skills"]),
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
    tabSkill: {
      get() {
        return this.$store.state.contentTestBuilderStore.tabSkill;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setTabSkill", value);
      },
    },
    skill_id: {
      get() {
        return this.$store.state.contentTestBuilderStore.skill_id;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setSkillId", value);
      },
    },
    type_form_skill: {
      get() {
        return this.$store.state.contentTestBuilderStore.type_form_skill;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setTypeFormSkill", value);
      },
    },
    section_id: {
      get() {
        return this.$store.state.contentTestBuilderStore.section_id;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setSectionId", value);
      },
    },
    tabSection: {
      get() {
        return this.$store.state.contentTestBuilderStore.tabSection;
      },
      set(value) {
        console.log(value);
        this.$store.commit("contentTestBuilderStore/setTabSection", value);
      },
    },
    type_form_section: {
      get() {
        return this.$store.state.contentTestBuilderStore.type_form_section;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setTypeFormSection", value);
      },
    },
    indexSkill: {
      get() {
        return this.$store.state.contentTestBuilderStore.indexSkill;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setIndexSkill", value);
      },
    },
    dialogSkill: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogSkill;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setDiaLogSkill", value);
      },
    },
    indexSection: {
      get() {
        return this.$store.state.contentTestBuilderStore.indexSection;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setIndexSection", value);
      },
    },
    dialogSection: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogSection;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setDialogSection", value);
      },
    },
    element_form_display: {
      get() {
        return this.$store.state.elementStore.element_form_display;
      },
      set(value) {
        this.$store.commit(
          "elementStore/setElementFormDisplay",
          value
        );
      },
    },
    dialogExercise: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogExercise;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setDialogExercise", value);
      },
    },
    type_form_exercise: {
      get() {
        return this.$store.state.contentTestBuilderStore.type_form_exercise;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setTypeFormExercise",
          value
        );
      },
    },
    indexExercise: {
      get() {
        return this.$store.state.contentTestBuilderStore.indexExercise;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setIndexExercise", value);
      },
    },
    exercise_id: {
      get() {
        return this.$store.state.contentTestBuilderStore.exercise_id;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setExerciseId", value);
      },
    },
    dialogQuestion: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogQuestion;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setDialogQuestion", value);
      },
    },
    type_form_question: {
      get() {
        return this.$store.state.contentTestBuilderStore.type_form_question;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setTypeFormQuestion",
          value
        );
      },
    },
    dialogPreview: {
      get() {
        return this.$store.state.informationTestBuilderStore.dialogPreview;
      },
      set(value) {
        this.$store.commit(
          "informationTestBuilderStore/setDialogPreview",
          value
        );
      },
    },
    dialogTimestampVideo: {
      get() {
        return this.$store.state.elementStore.dialogTimestampVideo;
      },
      set(value) {
        this.$store.commit(
          "elementStore/setDialogTimestampVideo",
          value
        );
      },
    },
    dialogSuggestOrderQuestion: {
      get() {
        return this.$store.state.contentTestBuilderStore
          .dialogSuggestOrderQuestion;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setDialogSuggestOrderQuestion",
          value
        );
      },
    },
    dialogQuestionVWR: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogQuestionVWR;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setDialogQuestionVWR",
          value
        );
      },
    },
    type_form_question_vwr: {
      get() {
        return this.$store.state.contentTestBuilderStore.type_form_question_vwr;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setTypeFormQuestionVWR",
          value
        );
      },
    },
    dialogSortSkill: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogSortSkill;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setDialogSortSkill", value);
      },
    },
    dialogQuestionIeltsVWRPart1: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogQuestionIeltsVWRPart1;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setDialogQuestionIeltsVWRPart1", value);
      },
    },
    type_form_question_ielts_vwr_part1: {
      get() {
        return this.$store.state.contentTestBuilderStore.type_form_question_ielts_vwr_part1;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setTypeFormQuestionIeltsVwrPart1", value);
      },
    },
    dialogQuestionUniversal: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogQuestionUniversal;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setDialogQuestionUniversal", value);
      },
    },
    type_form_question_universal: {
      get() {
        return this.$store.state.contentTestBuilderStore.type_form_question_universal;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setTypeFormQuestionUniversal", value);
      },
    },
    product_category_id: {
      get() {
        return this.$store.state.informationTestBuilderStore.product_category_id;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setProductCategoryId", value);
      },
    },
    list_course_by_category: {
      get() {
        return this.$store.state.informationTestBuilderStore.list_course_by_category;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setListCourseByCategory", value);
      },
    },
    topic_id: {
      get() {
        return this.$store.state.informationTestBuilderStore.topic_id;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setTopicId", value);
      },
    },
    list_situation_code: {
      get() {
        return this.$store.state.informationTestBuilderStore.list_situation_code;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setListSituationCode", value);
      },
    },
    situation_code: {
      get() {
        return this.$store.state.informationTestBuilderStore.situation_code;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setSituationCode", value);
      },
    },
  },
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Wizard" },
      { title: "Wizard-1" },
    ]);
    // Initialize form wizard
    let wizard = new KTWizard("kt_wizard_v1", {
      startStep: 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });
    // Validation before going to next page
    wizard.on("beforeNext", function (wizardObj) {
      let error = false;
      if(!vm.passeValidateFormLessTest()) {
        vm.$scrollTo("#formTest", 500);
        error = true;
        wizardObj.stop();
      }

      if (!error) vm.actionAddOrEditTest(wizardObj);
    });
    // Change event
    wizard.on("change", function (wizardObj) {
      vm.currentStep = wizardObj.currentStep;
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    this.setDataTest(this.dataTest, this.testId);
    this.getAllTag();
    this.getSectionPartConfig();
    this.getAllCharacterExercise();
    if (this.is_free !== undefined) {
      this.$store.commit("informationTestBuilderStore/setPaidTest", this.is_free === 1 ? false : true);
    }
    this.listWeightQuestion();
    this.getAllProductCategory();
    this.getListCourseByCategoryId(this.product_category_id);
    this.getListSituationCode();
    this.getListSubSituationCode();
    this.getAllLabel();
    this.listHskkLevel();
  },
  beforeDestroy() {
    this.$store.commit("informationTestBuilderStore/setTestId", null);
    this.$store.commit("informationTestBuilderStore/setPaidTest", false);
    this.$store.commit("informationTestBuilderStore/setTypeExpand", 0);
    this.$store.commit("informationTestBuilderStore/setTitleTest", "");
    this.$store.commit("informationTestBuilderStore/setSubTitle", "");
    this.$store.commit("informationTestBuilderStore/setTimeRestrictionTest", "No");
    this.$store.commit("informationTestBuilderStore/setDurationTest", null);
    this.$store.commit("informationTestBuilderStore/setDescriptionTest", "");
    this.$store.commit("informationTestBuilderStore/setCoverTest", null);
    this.$store.commit("informationTestBuilderStore/setCoverFileTest", null);
    this.$store.commit("informationTestBuilderStore/setIsUploadCoverTest", 1);
    this.$store.commit("contentTestBuilderStore/setSkills", []);
    this.$store.commit("informationTestBuilderStore/setPdfTest", null);
    this.$store.commit("informationTestBuilderStore/setPdfFileTest", null);
    this.$store.commit("informationTestBuilderStore/setIsUploadPdfTest", 1);
    this.$store.commit("informationTestBuilderStore/setPredict", 0);
    this.$store.commit("informationTestBuilderStore/setExpiredPredictDate", null);
    this.$store.commit("informationTestBuilderStore/setProductCategoryId", null);
    this.topic_id = null;
    this.situation_code = null;
  },
  watch: {
    product_category_id(val) {
      this.getListCourseByCategoryId(val);
    },
  },
  methods: {
    async getListCourseByCategoryId(categoryId) {
      if (categoryId !== this.product_category_constant.PREPTALK_ENGLISH) {
        this.list_course_by_category = [];
        return;
      }
      let vm = this;
      await ApiService.get("prep-app/courses/get-by-category-id/"+categoryId).then(function (response) {
        vm.list_course_by_category = response.data;
      });
    },
    async getListSituationCode() {
      try {
        let res = await ApiService.get("prep-app/test/list-situation-code?");
        if (res.status === 200) {
          this.list_situation_code = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getListSubSituationCode() {
      try {
        let res = await ApiService.get("prep-app/test/list-sub-situation-code?");
        if (res.status === 200) {
          this.$store.commit("informationTestBuilderStore/setListSubSituationCode", res.data);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getAllTag() {
      try {
        let res = await ApiService.get("prep-app/tag/limit");
        if (res.status === 200) {
          this.$store.commit("informationTestBuilderStore/setTags", res.data);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getAllCharacterExercise() {
      try {
        let res = await ApiService.get("prep-app/test/draft/exercise/character");
        if (res.status === 200) {
          this.$store.commit("exerciseConfigStore/setCharacter", res.data);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getSectionPartConfig() {
      try {
        let res = await ApiService.get("prep-app/test/draft/section/part-config");
        if (res.status === 200) {
          this.$store.commit("sectionPartConfig/setPartConfig", res.data);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getAllProductCategory() {
      try {
        let res = await ApiService.get("prep-app/product-category/limit");
        if (res.status === 200) {
          this.$store.commit("contentTestBuilderStore/setProductCategories", res.data);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getAllLabel() {
      try {
        let res = await ApiService.get("prep-app/label/limit");
        if (res.status === 200) {
          this.$store.commit("constantSupportForUnitStore/setLabels", res.data);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async listHskkLevel() {
      try {
        let res = await ApiService.get("prep-app/test/draft/skill/list-hskk-level");
        if (res.status === 200) {
          this.$store.commit("constantSupportForUnitStore/setListHskkLevel", res.data);
        }
      } catch (error) {
        console.log(error);
      }
    },
    setDataTest(test, test_id) {
      if (test_id != null) {
        this.$store.commit("informationTestBuilderStore/setTypeExpand", test.type_expand);
        this.$store.commit("informationTestBuilderStore/setTitleTest", test.test_title);
        this.$store.commit("informationTestBuilderStore/setSubTitle", test.sub_title === null ? "" : test.sub_title);
        this.$store.commit("informationTestBuilderStore/setDescriptionTest", test.test_description);
        this.$store.commit("informationTestBuilderStore/setTimeRestrictionTest", test.time_restriction === 0 ? "No" : "Yes");
        this.$store.commit("informationTestBuilderStore/setDurationTest", this.$options.filters.convertSecondToHM(test.duration));
        this.$store.commit("informationTestBuilderStore/setCoverTest", test.cover);
        this.$store.commit("informationTestBuilderStore/setIsUploadCoverTest", 1);
        this.$store.commit("informationTestBuilderStore/setTestId", test_id);
        this.$store.commit("contentTestBuilderStore/setSkills", test.skills);
        let grading_scale = test.grading_scale == null ? null : test.grading_scale.name;
        this.$store.commit("informationTestBuilderStore/setGradingScale", grading_scale);
        this.$store.commit("informationTestBuilderStore/setTagSelected", test.tags);
        this.$store.commit("informationTestBuilderStore/setPdfTest", test.pdf);
        this.$store.commit("informationTestBuilderStore/setIsUploadPdfTest", 1);
        this.$store.commit("informationTestBuilderStore/setPredict", test.predict);
        this.$store.commit("informationTestBuilderStore/setExpiredPredictDate", test.expired_predict_date);
        this.$store.commit("informationTestBuilderStore/setProductCategoryId", test.product_category_id);
        this.topic_id = test.topic_id;
        this.situation_code = test.situation_code ?? null;
      }
    },
    passeValidateFormLessTest() {
      let flat = true
      if (!this.title_test) {
        this.messError("Vui lòng nhập tiêu đề cho bài học / bài kiểm tra");
        flat = false;
      }
      if (this.time_restriction_test == "Yes") {
        if (!this.duration_test) {
          this.messError("Hãy nhập thời gian làm bài test");
          flat = false;
        }
      }
      if (![0,1].includes(this.type_expand)) {
        this.messError("Hãy chọn loại nội dung cho bài lesson/test");
        flat = false;
      }
      if (this.type_expand == 0) {
        if (!this.grading_scale) {
          this.messError("Hãy nhập thang điểm cho bài test");
          flat = false;
        }
      }
      if (this.predict == 1) {
        if (!this.expired_predict_date) {
          this.messError("Hãy nhập thời gian hết hạn");
          flat = false;
        }
      }
      if (!this.product_category_id) {
        this.messError("Vui lòng chọn kỳ thi cho bài học/ bài kiểm tra");
        flat = false;
      }
      if (!this.canPassContentTestWithPrepTalkEnglish()) {
        flat = false;
      }
      if (this.canInputCourseTopic()) {
        if (!this.topic_id) {
          this.messError("Vui lòng chọn topic PTE");
          flat = false;
        }
      }
      return flat;
    },
    canPassContentTestWithPrepTalkEnglish() {
      let flat = true;
      let vm = this;
      if (this.product_category_id === vm.product_category_constant.PREPTALK_ENGLISH) {
        let speakingSkillFilter = this.skills.filter((e) => e.id === vm.skill_constant.SPEAKING);
        if (speakingSkillFilter[0]) {
          let speakingSkill = speakingSkillFilter[0];
          let sections = speakingSkill.sections;
          if (sections.length) {
            let sectionHasPartConfigFilter = sections.filter((e) => e.partConfig);
            if (sectionHasPartConfigFilter.length) {
              // filter sectionHasPartConfigFilter has module = 4
              let sectionConfigNotInPrepTalkFilter = sectionHasPartConfigFilter.filter((e) => e.partConfig.module !== vm.section_config_module_constant.PREP_TALK_MODULE);
              if (sectionConfigNotInPrepTalkFilter.length) {
                this.messError("Nội dung bài lesson/test nhập trước đó không hợp lệ với kỳ thi PrepTalk English");
                flat = false;
              }
            }
          }
        }
      }

      return flat;
    },
    async actionAddOrEditTest(wizardObj) {
      console.log(wizardObj)
      let vm = this;
      vm.is_call_api = true;
      let checkDuration = vm.duration_test == null ? "00:00" : vm.duration_test;
      let formData = new FormData();
      formData.append("test_title", vm.title_test);
      formData.append("sub_title", vm.sub_title);
      formData.append("test_description", vm.description_test);
      formData.append("time_restriction", vm.time_restriction_test === "Yes" ? 1 : 0);
      formData.append("duration", vm.$options.filters.convertHMToSecond(checkDuration));
      formData.append("type_expand", vm.type_expand);
      formData.append("tag_ids", vm.tag_selected.map((e) => e.id));
      formData.append("product_category_id", vm.product_category_id);
      if (vm.topic_id) formData.append("topic_id", vm.topic_id);
      if (vm.situation_code) formData.append("situation_code", vm.situation_code);
      if (vm.grading_scale != null) formData.append("grading_scale", vm.grading_scale);
      if (vm.cover_test_file != null) {
        if (vm.is_upload_cover_test === 0) {
          formData.append("cover", vm.cover_test_file);
        }
      }
      if (vm.pdf_test_file != null) {
        if (vm.is_upload_pdf_test === 0) {
          formData.append("pdf_file", vm.pdf_test_file);
        }
      }
      formData.append("predict", vm.predict);
      if (vm.expired_predict_date)
        formData.append("expired_predict_date", vm.expired_predict_date);
      if (vm.test_id) {
        formData.append("_method", "PUT");
        await ApiService.post("prep-app/test/draft/" + vm.test_id, formData)
          .then(async function (res) {
            if (res.status === 200) {
              if (vm.product_category_id === vm.product_category_constant.PREPTALK_ENGLISH) {
                let skills = await vm.convertDataSkill(res.data.skills);
                vm.$store.commit("contentTestBuilderStore/setSkills", skills);
              }
              vm.$store.commit("informationTestBuilderStore/setIsUploadCoverTest", 1);
              vm.$store.commit("informationTestBuilderStore/setIsUploadPdfTest", 1);
              vm.is_call_api = false;
            }
          })
          .catch(function (error) {
            if (error) {
              console.log(error.response.data);
              if ([422].includes(error.response.status) ) {
                vm.messError(error.response.data.message);
                if (error.response.data.error.message) {
                  vm.messError(error.response.data.error.message);
                }
              } else {
                vm.messError('Có lỗi xảy ra. Vui lòng liên hệ bộ phận kỹ thuật hỗ trợ');
              }
              wizardObj.goPrev();
              vm.is_call_api = false;
            }
          });
      } else {
        await ApiService.post("prep-app/test/draft", formData)
          .then(async function (res) {
            if (res.status === 200) {
              vm.is_call_api = false;
              if (vm.product_category_id === vm.product_category_constant.PREPTALK_ENGLISH) {
                let skills = await vm.convertDataSkill(res.data.skills);
                vm.$store.commit("contentTestBuilderStore/setSkills", skills);
              }
              vm.$store.commit("informationTestBuilderStore/setTestId", res.data.id);
              vm.$store.commit("informationTestBuilderStore/setIsUploadCoverTest", 1);
              vm.$store.commit("informationTestBuilderStore/setIsUploadPdfTest", 1);
              vm.is_call_api = false;
            }
          })
          .catch(function (error) {
            if (error) {
              if ([422].includes(error.response.status) ) {
                vm.messError(error.response.data.message);
                if (error.response.data.error.message) {
                  vm.messError(error.response.data.error.message);
                }
              } else {
                vm.messError('Có lỗi xảy ra. Vui lòng liên hệ bộ phận kỹ thuật hỗ trợ');
              }
              wizardObj.goPrev();
              vm.is_call_api = false;
            }
          });
      }
    },
    btnDraftTest() {
      let vm = this;
      Swal.fire({
        title: "Bạn có chắc chắn muốn lưu nháp bài này?",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Có, tôi muốn lưu",
      }).then((result) => {
        if (result.value) {
          vm.is_call_api = true;
          let data = {
            test_id: vm.test_id,
            is_draft: 1,
            product_category_id: vm.product_category_id
          };
          ApiService.post("prep-app/test/draft/save-or-draft", data)
            .then(function (res) {
              if (res.status == 200) {
                vm.is_call_api = false;
                vm.$toasted.success("Submit successfully!!", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                if (vm.courseId != null) {
                  if (vm.type_form_test == "add") {
                    vm.$router.push({
                      name: "CourseContent",
                      params: { id: vm.courseId, dataTest: res.data },
                    });
                  } else {
                    vm.$router.push({
                      name: "CourseContent",
                      params: { id: vm.courseId },
                    });
                  }
                } else {
                  vm.$router.push({ name: "TabTestList" });
                }
              }
            })
            .catch(function (error) {
              if (error) {
                vm.is_call_api = false;
                vm.$toasted.error(
                  "Thao tác không thành công. Vui lòng liên hệ phòng kỹ thuật hỗ trợ",
                  {
                    theme: "toasted-primary",
                    position: "top-right",
                    duration: 4000,
                  }
                );
              }
            });
        }
      });
    },
    submit() {
      let validate = this.validateInputLessTest();
      if (validate) {
        let vm = this;
        Swal.fire({
          title: "Bạn có muốn lưu thông tin bài học / kiểm tra này?",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Xác nhận",
        }).then((result) => {
          if (result.value) {
            vm.is_call_api = true;
            let data = {
              test_id: vm.test_id,
              product_category_id: vm.product_category_id,
              is_draft: 0,
            };
            ApiService.post("prep-app/test/draft/save-or-draft", data)
              .then(function (res) {
                if (res.status == 200) {
                  vm.is_call_api = false;
                  vm.$toasted.success("Thao tác thành công!", {
                    theme: "toasted-primary",
                    position: "top-right",
                    duration: 4000,
                  });
                  if (vm.courseId != null) {
                    let dataTest = {};
                    if (vm.type_form_test == "add") {
                      dataTest = res.data;
                      dataTest.is_free = vm.paid_test == true ? false : true;
                      dataTest.type_form_test = "add";
                      dataTest.offset = vm.offset;
                      dataTest.duration = vm.duration_test == null ? null : vm.$options.filters.convertHMToSecond(vm.duration_test);
                      vm.$router.push({name: "CourseContent", params: { id: vm.courseId, dataTest: dataTest },});
                    } else {
                      vm.$router.push({
                        name: "CourseContent",
                        params: { id: vm.courseId },
                      });
                    }
                  } else {
                    vm.$router.push({ name: "TabTestList" });
                  }
                }
              })
              .catch(function (error) {
                if (error) {
                  vm.is_call_api = false;
                  vm.messError("Có lỗi xảy ra. Vui lòng liên hệ bộ phận kỹ thuật hỗ trợ");
                }
              });
          }
        });
      }
    },
    openDialogPreview() {
      console.log(this.test_id);
    },
    handleScroll() {
      let scrollTop = window.pageYOffset || document.scrollTop;
      this.scrollBtnSection = scrollTop > 560 ? true : false;
      this.scrollBtnExer = scrollTop > 650 ? true : false;
    },
    openDialogSkill() {
      this.type_form_skill = "add";
      this.$store.commit("contentTestBuilderStore/setDiaLogSkill", true);
    },
    editSkill(index) {
      this.type_form_skill = "update";
      this.indexSkill = index;
      this.$store.commit("contentTestBuilderStore/setDiaLogSkill", true);
    },
    deleteSkill(index, id_skill_test_mapping) {
      let vm = this;
      if (!canDeleteSkill()) return;
      Swal.fire({
        title: "Bạn có chắc chắc muốn xóa thông tin này?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Xác nhận",
      }).then((result) => {
        if (result.value) {
          vm.is_call_api = true;
          ApiService.delete(
            "prep-app/test/draft/skill/" + id_skill_test_mapping
          )
            .then(function (res) {
              if (res.status == 200) {
                // let data = vm.skills[index].skill_type;
                vm.skills.splice(index, 1);
                // vm.data_option_skills.push(data);
                vm.is_call_api = false;
              }
            })
            .catch(function (error) {
              console.log(error);
              if (error) {
                vm.is_call_api = false;
                if ([422].includes(error.response.status) ) {
                  vm.messError(error.response.data.error.message);
                } else {
                  vm.messError('Có lỗi xảy ra. Vui lòng liên hệ bộ phận kỹ thuật hỗ trợ');
                }
              }
            });
        }
      });

      function canDeleteSkill() {
        let flat = true;
        let skill = vm.skills[index];
        console.log(skill)
        if (vm.product_category_id === vm.product_category_constant.IELTS) {
          if (skill.vsr == 1) {
            flat = false;
            vm.messError("Không thể xóa kỹ năng speaking đã có cấu hình VSR");
          }

        }
        return flat;
      }
    },
    openDialogSection(index, skill_id) {
      this.type_form_section = "add";
      this.skill_id = skill_id;
      this.indexSkill = index;
      this.dialogSection = true;
    },
    openDialogExercise(indexSkill, indexSection, skill_id, section) {
      this.type_form_exercise = "add";
      this.skill_id = skill_id;
      this.section_id = section.id;
      this.indexSkill = indexSkill;
      this.indexSection = indexSection;
      this.element_form_display = "element";
      this.dialogExercise = true;
    },
    editExercise(indexSkill, indexSection, indexExercise, skill_id, section_id, exercise_id) {
      this.type_form_exercise = "update";
      this.dialogExercise = true;
      this.indexSkill = indexSkill;
      this.indexSection = indexSection;
      this.indexExercise = indexExercise;
      this.skill_id = skill_id;
      this.section_id = section_id;
      this.exercise_id = exercise_id;
      this.element_form_display = "element";
    },
    openDialogQuestion(indexSkill, indexSection, indexExercise, exercise_id) {
      this.indexSkill = indexSkill;
      this.indexSection = indexSection;
      this.indexExercise = indexExercise;
      this.exercise_id = exercise_id;
      this.element_form_display = null;
      let exercise = this.skills[indexSkill].sections[indexSection].exercises[indexExercise];
      if (exercise.exercise_type === this.exercise_type_constant.VIRTUAL_WRITING_ROOM) {
        this.type_form_question_vwr = "add";
        this.dialogQuestionVWR = true;
      } else if (exercise.exercise_type === this.exercise_type_constant.VIRTUAL_WRITING_ROOM_WT1_IELTS) {
        this.type_form_question_ielts_vwr_part1 = 'add';
        this.dialogQuestionIeltsVWRPart1 = true;
      } else if (exercise.exercise_type === this.exercise_type_constant.UNIVERSAL) {
        this.dialogQuestionUniversal = true;
        this.type_form_question_universal = 'add';
      } else {
        this.type_form_question = "add";
        this.dialogQuestion = true;
      }
    },
    openDialogTimeStamp(indexSkill, indexSection, section_id) {
      this.indexSkill = indexSkill;
      this.indexSection = indexSection;
      this.section_id = section_id;
      this.dialogTimestampVideo = true;
    },
    openDialogSuggestOrderQues(indexSkill, indexSection, indexExercise) {
      this.indexSkill = indexSkill;
      this.indexSection = indexSection;
      this.indexExercise = indexExercise;
      this.dialogSuggestOrderQuestion = true;
    },
    messError(msg = '') {
      this.$toasted.error(msg, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },
    validateInputLessTest() {
      let flat = true;

      for (let iSkill = 0; iSkill < this.skills.length; iSkill ++) {
        let skill = this.skills[iSkill];
        for (let iSection = 0; iSection < skill.sections.length; iSection ++) {
          let section = skill.sections[iSection];

          if (section.question_limit) {
            let countQuestionBySection = section.exercises.reduce((totalQuestion, exercise) => {
              let questionLength = exercise.questions !== undefined ? exercise.questions.length : 0;
              return totalQuestion + questionLength;
            },0);
            if (countQuestionBySection < section.question_limit) {
              this.messError('Vui lòng nhập đủ số câu hỏi của section: '+ section.section_title);
              flat = false;
            }
          }

        }

      }

      return flat;
    },
    async listWeightQuestion() {
      try {
        let res = await ApiService.get("prep-app/test/draft/question/weight-list");
        if (res.status === 200) {
          this.$store.commit("contentTestBuilderStore/setListWeight", res.data);
        }
      } catch (error) {
        console.log(error);
      }
    },
    openDialogSortSkill() {
      this.dialogSortSkill = true;
    },
    convertToNumber(value) {
      return Number(value);
    },
    canInputCourseTopic() {
      if (
          this.product_category_id === this.product_category_constant.PREPTALK_ENGLISH &&
          this.type_expand === this.test_type_expand_constant.TEST
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="css" scoped>
.v-tabs-slider-wrapper {
  height: 30px !important;
}
.v-tabs--vertical > .v-tabs-bar .v-tab {
  height: 35px !important;
  line-height: 2.5;
}
.wizard.wizard-1
  .wizard-nav
  .wizard-steps
  .wizard-step[data-wizard-state="done"]
  .wizard-label
  .wizard-title,
.wizard.wizard-1
  .wizard-nav
  .wizard-steps
  .wizard-step[data-wizard-state="current"]
  .wizard-label
  .wizard-title {
  color: #1479f4 !important;
}
.wizard.wizard-1
  .wizard-nav
  .wizard-steps
  .wizard-step[data-wizard-state="done"]
  .wizard-label
  .wizard-icon,
.wizard.wizard-1
  .wizard-nav
  .wizard-steps
  .wizard-step[data-wizard-state="current"]
  .wizard-label
  .wizard-icon {
  color: #1479f4 !important;
}
.wizard.wizard-1
  .wizard-nav
  .wizard-steps
  .wizard-step[data-wizard-state="done"]
  .wizard-arrow,
.wizard.wizard-1
  .wizard-nav
  .wizard-steps
  .wizard-step[data-wizard-state="current"]
  .wizard-arrow {
  color: #1479f4 !important;
}
.btn-action-builder {
  background-color: #1479f4 !important;
  color: white;
}
.menu-test-builder {
  position: fixed;
  right: 20px;
  bottom: 195px;
  overflow: auto;
  max-height: 70%;
}
.Ftext1-error-validate {
  position: absolute;
  bottom: 30px;
  color: red;
}
.icon-skill {
  padding-right: 10px;
}
.txtAddSection {
  text-align: center;
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
}
.default-padding {
  padding: 0;
  border: none;
}
.tab-section {
  text-align: left;
  display: list-item;
}
.input-skill {
  border: 1px solid white;
  padding: 0px;
  background-color: white;
  margin-top: 12px;
  line-height: 2;
}
.btn-add-file {
  background-color: #05cdff !important;
  color: white !important;
}
.btn-delete-file {
  background-color: #ff0000bf !important;
  color: white !important;
}
.text-description {
  font-weight: 400;
  max-width: 100%;
  font-style: oblique;
}
.scroll-btn-exer {
  color: white;
  position: fixed;
  top: 1px;
  z-index: 1000;
}
.text-scroll {
  cursor: pointer;
}
</style>
