<template>
  <div style="width: 100%">
    <v-col cols="12" class="mt-0">
      <p class="red--text m-0">* Hãy nhập các option và chọn đáp án đúng</p>
      <v-radio-group v-model="correctSingleSelect">
        <template v-for="(option, i) in optionAnswerSingleSelect">
          <div :key="i" class="d-flex" style="width: 100%">
            <p class="text-h5 mt-6 text-center" style="color: #6495ed; width: 2%">
              {{ i | getAlphabetCharacterFromIndex }}.
            </p>
            <v-radio :value="option" style="width: 53%">
              <template v-slot:label>
                <div class="mt-6 d-flex" style="width: 100%">
                  <v-text-field
                    :ref="'single-select-'+i"
                    dense outlined
                    :label="'Option' + (i + 1)"
                    :value="option.value"
                    @input="changeValueSingleSelect($event, i)"
                  ></v-text-field>
                  <v-tooltip bottom color="#181c32f0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          v-bind="attrs" v-on="on"
                          v-if="hasConfigPhonetic"
                          outlined color="#039BE5"
                          class="ml-1" small
                          @click="replacePinyinByTextSelectedViaInputOptionRef('single-select-'+i, i)"
                      >
                        Lấy pinyin
                      </v-btn>
                    </template>
                    <div style="max-width: 500px; color: white">
                      Thầy cô vui lòng bổ sung dấu cách giữa các từ có nghĩa trước khi thêm phiên âm để đảm bảo phiên âm hiển thị đúng vị trí các từ. Sau khi thêm phiên âm, thầy cô có thể điều chỉnh pinyin bằng cách edit nội dung pinyin giữa các thẻ &lt;/rt&gt;
                    </div>
                  </v-tooltip>
                  <v-btn class="ml-1" @click="deleteOptionSingleSelect(i)" small outlined color="red">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-radio>
            <div style="width: 45%; border: 1px solid grey" v-if="hasConfigPhonetic" class="mt-6 ml-1 rounded">
              <p class="subtitle-1 mb-0 border-bottom pl-1" style="background-color: #8080806e">Preview </p>
              <div
                  class="rounded px-1 py-2 container-pinyin-preview"
                  v-html="option.value">
              </div>
            </div>
          </div>
        </template>
      </v-radio-group>
    </v-col>
    <v-col cols="3" class="pt-0 mb-2">
      <v-btn large @click="addOptionSingleSelect" outlined color="blue">
        <v-icon>mdi-plus</v-icon> Thêm option
      </v-btn>
    </v-col>
  </div>
</template>

<script>
import phoneticSupport from "../Support/Question/phoneticSupport";

export default {
  name: "SingleSelect",
  mixins: [
    phoneticSupport
  ],
  props: {
    option: {
      type: Array,
      default: () => [{ value: "" }, { value: "" }],
    },
    correct: {},
    hasConfigPhonetic: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
    optionAnswerSingleSelect: {
      get() {
        return this.option;
      },
      set(value) {
        this.$emit("setOptionAnswer", value);
      },
    },
    correctSingleSelect: {
      get() {
        return this.correct;
      },
      set(value) {
        this.$emit("setCorrectAnswer", value);
      },
    },
  },
  methods: {
    messError(msg = '') {
      this.$toasted.error(msg, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },
    changeValueSingleSelect(event, i) {
      this.correctSingleSelect = event;
      this.optionAnswerSingleSelect.splice(i, 1, {value: event} );
    },
    deleteOptionSingleSelect(i) {
      if (this.optionAnswerSingleSelect.length <= 1) {
        this.messError('Phải có tối thiểu 1 option !');
        return;
      }
      this.optionAnswerSingleSelect.splice(i, 1);
    },
    addOptionSingleSelect() {
      let data = { value: "" };
      this.optionAnswerSingleSelect.push(data);
    },
    async replacePinyinByTextSelectedViaInputOptionRef(inputRef = '', indexOption) {
      if (!inputRef) {
        this.messError('Có lỗi ! Hãy báo bên kỹ thuật');
        return;
      }
      let refOption = this.$refs[inputRef][0].$refs;
      let input = refOption.input;

      const textSelected = input.value.substring(
          input.selectionStart,
          input.selectionEnd,
      );

      if (!textSelected) {
        this.messError('Chưa chọn vùng text để lấy pinyin');
        return;
      }

      const starSto = input.value.substring(0, input.selectionStart);
      const endSto = input.value.substring(input.selectionEnd, input.value.length);

      const dataPinyin = await this.getDataPinyinByTextSelected(textSelected);
      if (!dataPinyin) return;

      this.optionAnswerSingleSelect[indexOption].value =
          starSto +
          " " + dataPinyin + " " +
          endSto
      ;

      setTimeout(() => {
        input.focus();
        input.selectionStart = starSto.length + dataPinyin.length +2;
        input.selectionEnd = starSto.length + dataPinyin.length +2;
      }, 10);

    },
  },
};
</script>

<style scoped>
.container-pinyin-preview {
  height: auto;
  min-height: 40px;
  font-size: 28px;
  line-height: normal;
}
</style>
