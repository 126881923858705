<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <v-row justify="center">
      <v-dialog
        v-model="dialogStudyPlan"
        max-width="1400px"
        scrollable
        persistent
        :retain-focus="false"
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline" v-if="type_form === 'add'"
                >Tạo Study Plan</span
              >
              <span class="headline" v-else>Sửa Study Plan</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogStudyPlan = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Name:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-text-field
                        dense
                        v-model="name_input"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Mục tiêu đầu ra:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-text-field
                        dense
                        v-model="display_target_input"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Mô tả:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <Editor v-model="description_input"></Editor>
                    </v-col>
                  </v-row>
                  <v-row class="mt-6">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6"
                        >Duration: (Giờ:phút)</label
                      ></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <!--                      <date-picker class="" v-model="duration_input" type="time" format="HH:mm" value-type="format" placeholder="hh:mm"></date-picker>-->
                      <v-text-field
                        dense
                        outlined
                        v-model="duration_input"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mt-6">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Trạng thái:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-radio-group v-model="status_input" row class="mt-0">
                        <template v-for="(status, i) in status_plan">
                          <v-radio
                            :key="i"
                            :label="status.label"
                            :value="status.value"
                          ></v-radio>
                        </template>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Active:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-switch
                        class="mt-0 pt-0"
                        v-model="is_active_input"
                        :label="is_active_input ? 'Yes' : 'No'"
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'add'"
              @click="btnAddOrEditStudyPlan(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'update'"
              @click="btnAddOrEditStudyPlan(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogStudyPlan = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import Editor from "@/components/ckeditor5/Editor";
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";

export default {
  name: "StudyPlanForm",
  components: {
    Editor,
    Loading: () => import("vue-loading-overlay"),
  },
  props: {
    show_dialog: {
      type: Boolean,
      default: false,
    },
    type_form: {
      type: String,
      default: "",
    },
    status_plan: {
      type: Array,
      default: () => [],
    },
    category_id: {
      type: String,
      default: null,
    },
    level_id: {
      type: String,
      default: null,
    },
    new_offset: {
      type: Number,
      default: 0,
    },
    study_plan: {},
  },
  data() {
    return {
      is_call_api: false,
      name_input: "",
      description_input: "",
      status_input: 1,
      is_active_input: true,
      study_plan_id: null,
      display_target_input: "",
      duration_input: null,
    };
  },
  computed: {
    dialogStudyPlan: {
      get() {
        return this.show_dialog;
      },
      set(val) {
        this.$emit("setDialog", val);
      },
    },
  },
  watch: {
    dialogStudyPlan(val) {
      if (val) {
        this.setDataForm();
      }
    },
  },
  methods: {
    setDataForm() {
      if (this.type_form === "add") {
        this.name_input = "";
        this.description_input = "";
        this.status_input = 1;
        this.is_active_input = true;
        this.display_target_input = "";
        this.duration_input = null;
      } else {
        let data = JSON.parse(JSON.stringify(this.study_plan));
        this.study_plan_id = data.id;
        this.name_input = data.name;
        this.description_input = this.$utils.isValueInvalid(data.description) ? "" : data.description;
        this.status_input = parseInt(data.status);
        // this.duration_input = data.duration ? this.$options.filters.convertSecondToHM(data.duration) : null;
        this.duration_input = data.duration;
        this.is_active_input = data.is_active === 1;
        this.display_target_input = data.display_target;
      }
    },
    validateData() {
      let flat = true;
      if (this.name_input === null || this.name_input === "") {
        this.errorMess("Hãy nhập name !!")
        flat = false;
      }
      if (this.status_input === null || this.status_input === "") {
        this.errorMess("Hãy chọn trạng thái !!");
        flat = false;
      }
      return flat;
    },
    btnAddOrEditStudyPlan(type) {
      let validateQues = this.validateData();
      if (!validateQues) {
        return;
      }
      let data = {
        name: this.name_input,
        status: this.status_input,
        category_id: this.category_id,
        level_id: this.level_id,
        is_active: this.is_active_input ? 1 : 0,
      };
      if (this.display_target_input) data.display_target = this.display_target_input;
      if (this.duration_input) data.duration = this.duration_input;
      if (!this.$utils.isValueInvalid(this.description_input)) data.description = this.description_input;
      // if (this.duration_input) data.duration = this.$options.filters.convertHMToSecond(this.duration_input).toString();
      let vm = this;
      this.is_call_api = true;
      if (type === 1) {
        data.offset = this.new_offset;
        ApiService.post("prep-app/study-plan", data)
          .then(function (res) {
            if (res.status === 200) {
              vm.$toasted.success("Thêm thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.$emit("resetData");
              vm.is_call_api = false;
              vm.dialogStudyPlan = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      } else {
        ApiService.put("prep-app/study-plan/" + vm.study_plan_id, data)
          .then(function (res) {
            if (res.status === 200) {
              vm.$toasted.success("Sửa thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.$emit("resetData");
              vm.is_call_api = false;
              vm.dialogStudyPlan = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.errorMess(data_error[i]);
      }
    },
    errorMess(msg = 'Có lỗi xảy ra !') {
      this.$toasted.error(msg, {
        theme: "toasted-primary",
        position: "top-right",
        duration: 4000,
      });
    },
  },
};
</script>

<style scoped></style>
