<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="dialogTimestampVideo"
        persistent
        max-width="1200px"
        scrollable
        :retain-focus="false"
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Danh sách timestamp video</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogTimestampVideo = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="pb-0">

                  <template v-if="url_vimeo !== '' && url_vimeo != null">
                    <vue-vimeo-player
                      style="margin-top: -5rem; margin-bottom: -5rem"
                      class="d-flex justify-center align-content-start"
                      ref="player"
                      :videoUrl="url_vimeo"
                      @ready="onReady"
                      :player-height="height"
                      @seeked="getSeekVideo"
                    >
                    </vue-vimeo-player>
                  </template>
                </v-col>

                <v-col cols="2" lg="3" md="3" class="pb-0">
                  <label>Time:</label>
                  <v-text-field
                    readonly
                    v-model="timeInput"
                    class="text-body-1"
                    solo
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="8" lg="7" md="3" class="pb-0">
                  <label>Content:</label>
                  <v-textarea
                    solo
                    auto-grow
                    dense
                    rows="1"
                    class="text-body-1"
                    v-model="contentInput"
                  ></v-textarea>
                </v-col>
                <v-col cols="2" class="pb-0 pl-0 d-flex">
                  <v-btn
                    @click="addDataTimestamp"
                    class="mt-6"
                    small
                    outlined
                    color="cyan"
                  >
                    <v-icon small dark>mdi-check-outline</v-icon>Save
                  </v-btn>
                  <v-btn
                    @click="resetDataTimestamp"
                    class="mt-6 ml-2"
                    small
                    outlined
                    color="red"
                  >
                    <v-icon small dark>mdi-restore</v-icon>Reset
                  </v-btn>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <div class="table-responsive">
                    <table
                      class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
                    >
                      <thead>
                        <tr>
                          <th class="text-button" scope="col">Time</th>
                          <th class="text-button" scope="col">Content</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="(data, i) in dataTimestampVideo">
                          <tr :key="i">
                            <td
                              @click="setTimePlay(data.time, i)"
                              class="text-time"
                            >
                              {{ data.time }}
                            </td>
                            <td class="text-justify" style="max-width: 55rem">
                              <pre class="content-timestamp"
                                >{{ data.content }}
                            </pre
                              >
                            </td>
                            <td style="display: flex">
                              <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    color="#1c5580"
                                    @click="detailTimestampVideo(i)"
                                    >mdi-pencil</v-icon
                                  >
                                </template>
                                <span>Edit</span>
                              </v-tooltip>
                              <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    v-bind="attrs"
                                    class="ml-2"
                                    v-on="on"
                                    color="red"
                                    @click="deleteTimestampVideo(i)"
                                    >mdi-delete</v-icon
                                  >
                                </template>
                                <span>Delete</span>
                              </v-tooltip>
                            </td>
                          </tr>
                        </template>
                        <template v-if="!dataTimestampVideo.length">
                          <tr>
                            <td style="text-align: center" colspan="2">
                              No data
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darken-1" text @click="dialogTimestampVideo = false"
              >Đóng</v-btn
            >
            <v-btn
              class="font-weight-bold"
              outlined
              color="blue darken-1"
              text
              @click="btnSaveTimestamp"
              >Lưu</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { vueVimeoPlayer } from "vue-vimeo-player";
import Swal from "sweetalert2";
import ApiService from "@/service/api.service";
export default {
  name: "TimestampVideoModal",
  components: {
    vueVimeoPlayer,
  },
  data() {
    return {
      height: 500,
      options: {
        muted: true,
        autoplay: true,
      },
      playerReady: false,
      url_vimeo: "",
      timeInput: null,
      contentInput: "",
      dataTimestampVideo: [],
      timestamp_video_index: null,
      type_form: null,
      element_id: null,
    };
  },
  computed: {
    ...mapState({
      section_id: (state) => state.contentTestBuilderStore.section_id,
      indexSkill: (state) => state.contentTestBuilderStore.indexSkill,
      indexSection: (state) => state.contentTestBuilderStore.indexSection,
    }),
    ...mapGetters({
      skills: "contentTestBuilderStore/skills",
    }),
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
    dialogTimestampVideo: {
      get() {
        return this.$store.state.elementStore.dialogTimestampVideo;
      },
      set(value) {
        this.$store.commit(
          "elementStore/setDialogTimestampVideo",
          value
        );
      },
    },
  },
  watch: {
    dialogTimestampVideo: function (val) {
      if (val) {
        this.setDataInput();
      } else {
        // this.url_vimeo = "";
        // this.element_id = null;
        // this.dataTimestampVideo = [];
        this.resetDataTimestamp();
        this.pause();
      }
    },
  },
  methods: {
    setDataInput() {
      let data = this.skills[this.indexSkill].sections[this.indexSection];
      this.url_vimeo = data.element_data;
      this.element_id = data.element_id;
      this.dataTimestampVideo = JSON.parse(
        JSON.stringify(data.element_timestamp)
      );
    },
    onReady() {
      this.playerReady = true;
    },
    play() {
      this.$refs.player.play();
    },
    pause() {
      this.$refs.player.pause();
    },
    async btnSaveTimestamp() {
      let vm = this;
      let data = {
        element_id: this.element_id,
        timestamp_video: this.dataTimestampVideo,
      };
      this.is_call_api = true;
      await ApiService.post("prep-app/element/timestamp", data)
        .then(function (res) {
          if (res.status === 200) {
            console.log(JSON.parse(JSON.stringify(vm.dataTimestampVideo)));
            vm.skills[vm.indexSkill].sections[
              vm.indexSection
            ].element_timestamp = JSON.parse(
              JSON.stringify(vm.dataTimestampVideo)
            );
            vm.dialogTimestampVideo = false;
            vm.is_call_api = false;
          }
        })
        .catch(function (error) {
          console.log(error);
          if (error) {
            vm.is_call_api = false;
            vm.$toasted.error(
              "Something Wrong. Please try again or contact admin !!",
              {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              }
            );
          }
        });
      this.skills[this.indexSkill].sections[
        this.indexSection
      ].element_timestamp = this.dataTimestampVideo;
    },
    getSeekVideo($event) {
      console.log($event)
      if (this.type_form === "update") {
        this.timeInput = this.$options.filters.convertSecondToHMS(
          $event.seconds
        );
        this.resetDataTimestamp();
      } else {
        this.timeInput = this.$options.filters.convertSecondToHMS(
          $event.seconds
        );
      }
    },
    setTimePlay(hms, index) {
      this.detailTimestampVideo(index);
      let seconds = this.$options.filters.convertHMSToSecond(hms);
      this.$refs.player.player.setCurrentTime(seconds);
    },
    validateData() {
      let flat = true;
      if (this.timeInput === "" || this.timeInput == null) {
        this.$toasted.error("Chưa chọn thời gian !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.contentInput == null || this.contentInput === "") {
        this.$toasted.error("Chưa nhập nội dung !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      let checkDuplicateTime = this.dataTimestampVideo.filter((e) => {
        return e.time === this.timeInput;
      });
      if (checkDuplicateTime.length > 0) {
        if (this.type_form !== "update") {
          this.$toasted.error("Timestamp này đã được nhập !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          flat = false;
        }
      }
      return flat;
    },
    addDataTimestamp() {
      let vm = this;
      let validateQues = this.validateData();
      if (!validateQues) {
        return;
      }
      let data = {
        time: this.timeInput,
        content: this.contentInput,
      };
      if (this.type_form === "update") {
        this.dataTimestampVideo[this.timestamp_video_index] = JSON.parse(
          JSON.stringify(data)
        );
      } else {
        this.dataTimestampVideo.push(data);
      }
      this.dataTimestampVideo.sort(function (a, b) {
        return (
          vm.$options.filters.convertHMSToSecond(a.time) -
          vm.$options.filters.convertHMSToSecond(b.time)
        );
      });
      this.timeInput = null;
      this.contentInput = "";
    },
    resetDataTimestamp() {
      this.timeInput = null;
      this.contentInput = "";
      this.timestamp_video_index = null;
      this.type_form = null;
    },
    detailTimestampVideo(index) {
      let data = this.dataTimestampVideo[index];
      this.timeInput = data.time;
      this.contentInput = data.content;
      this.timestamp_video_index = index;
      this.type_form = "update";
    },
    deleteTimestampVideo(index) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          vm.dataTimestampVideo.splice(index, 1);
        }
      });
    },

  },
};
</script>

<style scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.text-time {
  color: #43a047;
  cursor: pointer;
}
.content-timestamp {
  font-family: Montserrat;
  font-size: 1.1rem;
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  margin: 0;
  padding-top: 0.5rem;
}
</style>
