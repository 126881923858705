<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="dialogAddAudioTime"
        persistent
        max-width="1000px"
        scrollable
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Add Audio Time</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogAddAudioTime = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <template v-if="type_element == 'Audio'">
                  <v-col cols="12">
                    <VAudio
                      :file="element_audio_section"
                      @setInputTime="setAudioTime"
                    ></VAudio>
                    <p class="red--text mt-2">
                      * Click the progress bar to get the time you want to
                      select
                    </p>
                  </v-col>
                  <v-col cols="7">
                    <b-row>
                      <b-col sm="3">
                        <h4 class="float-right mt-2">Time:</h4>
                      </b-col>
                      <b-col sm="9">
                        <b-form-input
                          disabled
                          v-model="audio_timer"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                  </v-col>
                </template>
                <template v-if="type_element == 'Video'">
                  <v-col cols="12">
                    <b-embed
                      type="iframe"
                      aspect="16by9"
                      :src="element_audio_section"
                    ></b-embed>
                  </v-col>
                  <v-col cols="7">
                    <v-row>
                      <v-col sm="3">
                        <h4 class="float-right mt-2">Time:</h4>
                      </v-col>
                      <v-col sm="9">
                        <!-- <b-form-input v-model="audio_timer"></b-form-input> -->
                        <date-picker
                          v-model="audio_timer"
                          type="time"
                          placeholder="HH-MM-SS"
                        ></date-picker>
                      </v-col>
                    </v-row>
                  </v-col>
                </template>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="btnAddAudioTimeExercise"
              >Add</v-btn
            >
            <v-btn
              color="blue darken-1"
              text
              @click="dialogAddAudioTime = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ApiService from "@/service/api.service";
import { mapState, mapGetters } from "vuex";
export default {
  name: "AudioTimeModal",
  components: {
    VAudio: () => import("@/view/pages/marking-management/Audio"),
  },
  data() {
    return {
      audio_timer: null,
    };
  },
  computed: {
    ...mapState({
      exercise_id: (state) => state.contentTestBuilderStore.exercise_id,
      indexSkill: (state) => state.contentTestBuilderStore.indexSkill,
      indexSection: (state) => state.contentTestBuilderStore.indexSection,
      indexExercise: (state) => state.contentTestBuilderStore.indexExercise,
      element_audio_section: (state) =>
        state.elementStore.element_audio_section,
    }),
    ...mapGetters({
      skills: "contentTestBuilderStore/skills",
    }),
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
    dialogAddAudioTime: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogAddAudioTime;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setDialogAddAudioTime",
          value
        );
      },
    },
    type_element: {
      get() {
        return this.$store.state.elementStore.type_element;
      },
      set(value) {
        this.$store.commit("elementStore/setTypeElement", value);
      },
    },
  },
  watch: {
    dialogAddAudioTime: function (val) {
      if (val) {
        let timer = this.skills[this.indexSkill].sections[this.indexSection].exercises[this.indexExercise].audio_timer;
        if (this.type_element == "Video") {
          this.audio_timer = timer == null ? timer : this.$options.filters.convertSecondToOjbTime(timer);
        }
        if (this.type_element == "Audio") {
          this.audio_timer = timer == null ? timer : this.$options.filters.convertSecondToHMS(timer);
        }
      }
    },
  },
  methods: {
    setAudioTime(time) {
      this.audio_timer = time;
    },
    async btnAddAudioTimeExercise() {
      if (this.audio_timer == null) {
        this.$toasted.error(" Time is required !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 2000,
        });
        return;
      }
      let vm = this;
      let data = {
        exercise_id: this.exercise_id,
        audio_timer: this.checkAddTime(this.audio_timer),
      };
      this.is_call_api = true;
      await ApiService.post("prep-app/test/draft/exercise/audio-timer", data)
        .then(function (res) {
          if (res.status == 200) {
            vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].audio_timer = vm.checkAddTime(vm.audio_timer);
            vm.$set(vm.skills);
            vm.is_call_api = false;
            vm.dialogAddAudioTime = false;
          }
        })
        .catch(function (error) {
          if (error) {
            vm.is_call_api = false;
            vm.dialogAddAudioTime = false;
            vm.$toasted.error(
              "Something Wrong. Please try again or contact admin !!",
              {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              }
            );
          }
        });
    },
    checkAddTime(data) {
      let time = null;
      if (this.type_element == "Video") {
        time = this.$options.filters.convertTimeToSecond(data);
      } else if (this.type_element == "Audio") {
        time = this.$options.filters.convertHMSToSecond(data);
      }
      return time;
    },
  },
};
</script>

<style></style>
